import React from 'react';
import SectionTitle from '../shared/SectionTitle';
import durationIcon from '@assets/courses/svg/duration-clock.svg';
import Image from 'next/image';

const LearningJourneyHeader = ({ title, duration }) => {
  return (
    <div className="mb-7 flex w-full flex-wrap items-start md:mb-0">
      <div className="w-full md:w-fit">
        <SectionTitle subTitle={title} strikePosition="left" />
      </div>
      {duration && (
        <>
          <hr className="mx-4 mt-4 hidden flex-1 border-0 border-b-4 border-dotted border-red md:block" />
          <div className="flex items-center justify-end md:mb-5">
            <div className="relative h-3 w-3 md:h-[18px] md:w-[18px]">
              <Image src={durationIcon} alt="" layout="fill" objectFit="cover" />
            </div>
            <p className="ml-2 font-satoshi-medium text-base font-bold  leading-5 text-red md:text-2xl md:leading-7">
              {duration}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default LearningJourneyHeader;
