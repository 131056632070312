import SliderComponent from '@components/shared/CustomSlider';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import { getImgUrl } from '@utils/functions/getImgUrl';
import Image from 'next/image';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { HierarchyPrimary } from 'stories/Button.stories';

function GenAIProjects({ projects = [] }) {
  if (!projects || !projects?.length) return <></>;

  return (
    <div className="flex flex-col gap-y-[40px] py-[40px] px-[16px] md:gap-y-[80px] md:py-[50px] md:px-[124px]">
      <div className="flex flex-col items-center gap-y-[16px]">
        <NewSectionTitle text={'Explore Our Cutting-Edge '} highlightedText={'AI Projects'} />
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          Discover AI projects that showcase real-world applications and help you build practical
          skills. These hands-on projects demonstrate how AI is transforming industries like
          education, healthcare, and more, offering valuable learning experiences for all levels.
        </p>
      </div>

      <div className="w-full">
        <SliderComponent
          swiperOptions={{
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 16,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            },
          }}
          buttonStyle={{
            size: 24,
            buttonClass: 'col-span-6 rounded-full',
            leftButton: 'place-self-end',
            rightButton: 'place-self-start',
          }}
        >
          {projects.map((project) => (
            <GenAiProjectCard key={project.id} {...project} />
          ))}
        </SliderComponent>
      </div>
    </div>
  );
}

export default GenAIProjects;

function GenAiProjectCard({ projectImage, projectTitle = '', projectDescription = '' }) {
  return (
    <div className="grid h-full overflow-hidden rounded-lg bg-white shadow-md">
      <div className="grid w-full">
        <Image
          src={getImgUrl(projectImage)}
          height={300}
          width={500}
          objectFit="fill"
          loading="lazy"
        />
      </div>

      <div className="flex h-full flex-col gap-y-[16px] p-[24px]">
        <h4 className="text-start font-satoshi-medium text-[20px] font-medium leading-7">
          {projectTitle}
        </h4>
        <p className="text-start text-[16px] leading-5 text-gray-navigation">
          {projectDescription}
        </p>

        <HierarchyPrimary
          linkCTA
          target={'_blank'}
          href={
            'https://grow.almabetter.com/auth/signup?mastersDSInterest=true&redirectTo=https://grow.almabetter.com/masters-ds/projects'
          }
          label={'Start Now'}
          size="large"
          customStyle={'mt-auto'}
        >
          <FaArrowRight size={20} />
        </HierarchyPrimary>
      </div>
    </div>
  );
}
