import { getImgUrl } from '@utils/functions/getImgUrl';
import NextCohort from '../shared/NextCohort';
import SectionTitle from '../shared/SectionTitle';
import Image from 'next/image';
import { useState } from 'react';
import CertificateZoomModal from './CertificateZoomModal';
import AccreditationList from '@components/local/degree/accreditionList/AccreditionList';
import { Tab } from '@headlessui/react';
import { useDispatch } from '@globalStore/storeProvider';
import { Primary, SecondaryColor } from 'stories/Button.stories';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import { COURSE_TYPE } from '@lib/constants';
import { replaceTemplateStrings } from '@utils/helper';

const CertificationSection = ({
  certificateData,
  nextCohort,
  courseCTAs,
  degree,
  DownloadBrouchure,
  courseData,
  newTitle,
  subTitle,
}) => {
  const { Certificates, courseInterest } = courseData;
  const { title } = certificateData || {};
  const { buttonText, buttonLink, freeTrialLink, freeTrialText } = courseCTAs;
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <>
      {degree ? (
        <MastersCertificationSection
          courseInterest={courseInterest}
          getImgUrl={getImgUrl}
          setOpen={setOpen}
          Certificates={Certificates}
          DownloadBrouchure={DownloadBrouchure}
          freeTrialLink={freeTrialLink}
          freeTrialText={freeTrialText}
          title={newTitle}
          subTitle={subTitle}
        />
      ) : (
        <div className="bg-[#F4E7E7] px-6 pt-6 !pb-36 lg:px-16">
          <SectionTitle title={title} strikePosition="left" />
          <Tab.Group>
            <Tab.List className="mb-8 flex gap-4 space-x-1 overflow-scroll rounded-xl p-1">
              {Certificates.map(({ Title }, index) => (
                <Tab
                  key={Title}
                  className={({ selected }) =>
                    classNames(
                      ' border-b-4 px-3 py-2 font-satoshi-medium text-base font-medium  outline-none lg:text-xl',
                      selected ? 'border-red text-red' : 'border-transparent text-black'
                    )
                  }
                >
                  <h3 className="flex flex-col items-start text-left capitalize  leading-5 md:px-6">
                    {Title}
                  </h3>
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {Certificates?.map((item, index) => (
                <Tab.Panel
                  key={index}
                  className="grid items-center justify-center gap-y-20 lg:grid-cols-12 lg:gap-16"
                >
                  <div className="col-span-7 not-last:child:mb-8 lg:col-span-5">
                    {item?.Features?.map(({ Title, Description, Icon }) => (
                      <section key={Title} className="mb-6 grid grid-cols-7 items-center gap-8">
                        <div className="col-span-2">
                          <Image
                            alt="icon"
                            src={getImgUrl(Icon)}
                            width={100}
                            height={100}
                            objectFit="contain"
                          />
                        </div>
                        <div className="col-span-5">
                          <h4 className="font-satoshi-medium text-[18px] font-semibold">{Title}</h4>
                          <p className="mt-1 text-[16px] leading-5 text-gray-300">{Description}</p>
                        </div>
                      </section>
                    ))}
                    {degree && item?.Title?.toLowerCase()?.includes('woolf') && (
                      <div>
                        <section className="mt-4">
                          <AccreditationList />
                        </section>
                        {/* <p className="mt-2 mb-6 text-[0.85rem] font-normal italic leading-6 text-[#5a6273] md:text-sm">
                      *AlmaBetter Innovarsity is a constituent college affiliated with&nbsp;
                      <a
                        href={
                          'https://woolf.university/academic/college/09d88ade-66bd-4b84-8aab-b70232434c2b'
                        }
                        target="_blank"
                        className="cursor-pointer underline"
                      >
                        Woolf
                      </a>
                    </p> */}
                      </div>
                    )}
                    {!degree && item.Title != 'E&ICT Academy, IIT Guwahati' ? (
                      <>
                        <>
                          <NextCohort data={nextCohort} />
                          <div className="grid gap-3 md:grid-cols-2">
                            {DownloadBrouchure ? (
                              <DownloadBrouchure size="large" />
                            ) : (
                              <Primary
                                linkCTA
                                href={freeTrialLink}
                                target="_blank"
                                label={freeTrialText}
                                size="large"
                                capitalCase
                              />
                            )}
                            <SecondaryColor
                              linkCTA
                              href={buttonLink}
                              target="_blank"
                              data-cta="enrol"
                              label={buttonText}
                              size="xl"
                              capitalCase
                            />
                          </div>
                        </>
                      </>
                    ) : (
                      !degree && (
                        <div className="flex">
                          <button
                            className="btn-red flex h-full w-3/5 items-center justify-center"
                            onClick={() =>
                              dispatch({
                                type: 'SET_WIDGET_SOURCE',
                                payload: 'request_call_back_widget',
                              })
                            }
                          >
                            Talk to Admission's Team
                          </button>
                        </div>
                      )
                    )}
                  </div>

                  <div className="col-span-7">
                    <div>
                      <img
                        src={getImgUrl(item.Image)}
                        alt="certificate"
                        className="w-full cursor-pointer rounded-lg object-contain"
                        onClick={() =>
                          setOpen({
                            status: true,
                            data: item.Image,
                          })
                        }
                      />
                      <div
                        onClick={() =>
                          setOpen({
                            status: true,
                            data: item.Image,
                          })
                        }
                        className="flex cursor-pointer items-center justify-end gap-2 pr-4 hover:underline"
                      >
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3077 9.69247H6.6923C6.31384 9.69247 6 9.37863 6 9.00017C6 8.62171 6.31384 8.30786 6.6923 8.30786H11.3077C11.6861 8.30786 12 8.62171 12 9.00017C12 9.37863 11.6861 9.69247 11.3077 9.69247Z"
                            fill="#6C6C6C"
                          />
                          <path
                            d="M9.00004 12C8.62158 12 8.30774 11.6861 8.30774 11.3077V6.6923C8.30774 6.31384 8.62158 6 9.00004 6C9.3785 6 9.69235 6.31384 9.69235 6.6923V11.3077C9.69235 11.6861 9.3785 12 9.00004 12Z"
                            fill="#6C6C6C"
                          />
                          <path
                            d="M9.10415 17.2187C4.63123 17.2187 0.989563 13.577 0.989563 9.10409C0.989563 4.63117 4.63123 0.989502 9.10415 0.989502C13.5771 0.989502 17.2187 4.63117 17.2187 9.10409C17.2187 13.577 13.5771 17.2187 9.10415 17.2187ZM9.10415 2.177C5.2804 2.177 2.17706 5.28825 2.17706 9.10409C2.17706 12.9199 5.2804 16.0312 9.10415 16.0312C12.9279 16.0312 16.0312 12.9199 16.0312 9.10409C16.0312 5.28825 12.9279 2.177 9.10415 2.177Z"
                            fill="#6C6C6C"
                          />
                          <path
                            d="M17.4167 18.0103C17.2663 18.0103 17.1158 17.9549 16.9971 17.8361L15.4138 16.2528C15.1842 16.0232 15.1842 15.6432 15.4138 15.4136C15.6433 15.1841 16.0233 15.1841 16.2529 15.4136L17.8363 16.997C18.0658 17.2266 18.0658 17.6066 17.8363 17.8361C17.7175 17.9549 17.5671 18.0103 17.4167 18.0103Z"
                            fill="#6C6C6C"
                          />
                        </svg>
                        <p className="font-satoshi-medium text-sm font-semibold text-gray-300">
                          Click to zoom
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
          {degree ? (
            <div className="flex w-full flex-col items-center space-y-5">
              <div className="grid place-content-center gap-3 pt-4 md:grid-cols-2">
                <Primary
                  linkCTA
                  href={freeTrialLink}
                  target="_blank"
                  label={freeTrialText}
                  size="xl"
                  capitalCase
                />

                {DownloadBrouchure && <DownloadBrouchure outline size="xl" />}
              </div>
              <NextCohort data={nextCohort} noBackground degree={degree} />
            </div>
          ) : null}
        </div>
      )}

      {open.status && (
        <CertificateZoomModal
          open={open.status}
          close={() => setOpen({ status: false, data: null })}
          content={
            <img
              src={getImgUrl(open.data)}
              alt="certificate"
              className="h-full w-full object-contain"
            />
          }
        />
      )}
    </>
  );
};

export default CertificationSection;

function MastersCertificationSection({
  Certificates = [],
  courseInterest,
  setOpen,
  getImgUrl,
  freeTrialLink,
  freeTrialText,
  DownloadBrouchure,
  title,
  subTitle,
}) {
  if (Certificates.length === 0) return <></>;

  const certificateDesc = replaceTemplateStrings(
    'Elevate your career with our esteemed certification. Unlock new opportunities and demonstrate your mastery in {{courseNameSmall}}.',
    { courseNameSmall: courseInterest === COURSE_TYPE.WEB3 ? 'web development' : 'data science' }
  );

  return (
    <div className="flex flex-col items-center gap-y-[72px] px-[16px] pt-[40px] pb-[50px] md:px-[124px] md:pt-[112px] md:pb-[105px]">
      <div className="flex flex-col gap-y-[24px] md:gap-y-[12px]">
        <NewSectionTitle
          text={title || 'AlmaBetter’s Degree of'}
          highlightedText={subTitle || 'Excellence'}
        />

        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          {certificateDesc}
        </p>
      </div>

      <Tab.Group>
        <Tab.List className={'flex items-center gap-x-[32px] md:self-start'}>
          {Certificates.map(({ Title }, index) => (
            <Tab
              key={Title}
              className={({ selected }) =>
                `border-b-2 text-[18px] normal-case leading-7 outline-none md:text-[24px] md:leading-8 ${
                  selected ? 'border-red text-red' : 'border-transparent'
                }`
              }
            >
              <h3>{Title}</h3>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {Certificates.map((item, index) => (
            <Tab.Panel
              key={index}
              className={'grid items-center gap-x-[64px] gap-y-[42px] md:grid-cols-12'}
            >
              <div className="flex flex-col gap-y-[32px] md:col-span-5">
                {item?.Features?.map(({ Title, Description, Icon }, index) => (
                  <ComboCard {...{ Title, Description, Icon }} key={index} />
                ))}
                <div className="grid w-full grid-cols-2 items-center gap-x-[12px] md:flex">
                  <Primary
                    linkCTA
                    href={freeTrialLink}
                    target="_blank"
                    label={freeTrialText}
                    size="large"
                  />

                  {DownloadBrouchure && (
                    <DownloadBrouchure outline size="large" capitalCase={false} />
                  )}
                </div>
              </div>

              <div className="order-first flex flex-col items-end md:order-none md:col-span-7">
                <div className="relative w-full">
                  <Image
                    src={getImgUrl(item.Image)}
                    alt="certificate"
                    objectFit="contain"
                    className="w-full cursor-pointer rounded-lg"
                    width={684}
                    height={480}
                    loading="lazy"
                    onClick={() =>
                      setOpen({
                        status: true,
                        data: item.Image,
                      })
                    }
                  />
                  {/* <img
                    src={getImgUrl(item.Image)}
                    alt="certificate"
                    className="w-full cursor-pointer rounded-lg object-contain"
                    onClick={() =>
                      setOpen({
                        status: true,
                        data: item.Image,
                      })
                    }
                  /> */}
                </div>
                <div
                  onClick={() =>
                    setOpen({
                      status: true,
                      data: item.Image,
                    })
                  }
                  className="flex cursor-pointer items-center justify-end gap-2 pr-4 hover:underline"
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3077 9.69247H6.6923C6.31384 9.69247 6 9.37863 6 9.00017C6 8.62171 6.31384 8.30786 6.6923 8.30786H11.3077C11.6861 8.30786 12 8.62171 12 9.00017C12 9.37863 11.6861 9.69247 11.3077 9.69247Z"
                      fill="#6C6C6C"
                    />
                    <path
                      d="M9.00004 12C8.62158 12 8.30774 11.6861 8.30774 11.3077V6.6923C8.30774 6.31384 8.62158 6 9.00004 6C9.3785 6 9.69235 6.31384 9.69235 6.6923V11.3077C9.69235 11.6861 9.3785 12 9.00004 12Z"
                      fill="#6C6C6C"
                    />
                    <path
                      d="M9.10415 17.2187C4.63123 17.2187 0.989563 13.577 0.989563 9.10409C0.989563 4.63117 4.63123 0.989502 9.10415 0.989502C13.5771 0.989502 17.2187 4.63117 17.2187 9.10409C17.2187 13.577 13.5771 17.2187 9.10415 17.2187ZM9.10415 2.177C5.2804 2.177 2.17706 5.28825 2.17706 9.10409C2.17706 12.9199 5.2804 16.0312 9.10415 16.0312C12.9279 16.0312 16.0312 12.9199 16.0312 9.10409C16.0312 5.28825 12.9279 2.177 9.10415 2.177Z"
                      fill="#6C6C6C"
                    />
                    <path
                      d="M17.4167 18.0103C17.2663 18.0103 17.1158 17.9549 16.9971 17.8361L15.4138 16.2528C15.1842 16.0232 15.1842 15.6432 15.4138 15.4136C15.6433 15.1841 16.0233 15.1841 16.2529 15.4136L17.8363 16.997C18.0658 17.2266 18.0658 17.6066 17.8363 17.8361C17.7175 17.9549 17.5671 18.0103 17.4167 18.0103Z"
                      fill="#6C6C6C"
                    />
                  </svg>
                  <p className="font-satoshi-medium text-sm font-semibold text-gray-300">
                    Click to zoom
                  </p>
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

function ComboCard({ Title, Description, Icon }) {
  const iconPath = typeof Icon === 'string' ? Icon : getImgUrl(Icon);

  return (
    <section key={Title} className="grid grid-cols-6 gap-x-[16px] md:gap-x-[24px]">
      <div>
        <Image
          alt="icon"
          src={iconPath}
          width={60}
          height={68}
          objectFit="contain"
          loading="lazy"
        />
      </div>
      <div className="col-span-5 flex flex-col gap-y-[8px]">
        <h4 className="font-satoshi-bold text-[16px] leading-6 md:text-[20px] md:leading-[30px]">
          {Title}
        </h4>
        <p className="text-gray-[#667085] font-satoshi-regular text-[12px] leading-[18px] md:text-[16px] md:leading-6">
          {Description}
        </p>
      </div>
    </section>
  );
}
