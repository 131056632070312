import React, { useEffect, useState } from 'react';
import TrackCard from './TrackCard';
import ListLayout from './ListLayout';
import TopicCard from './TopicCard';

import python from '@assets/skills/python.png';
import pandas from '@assets/skills/pandas.png';
import numpy from '@assets/skills/numpy.png';
import pytorch from '@assets/skills/pytorch.png';
import matplotlib from '@assets/skills/matplotlib.png';
import SQL from '@assets/skills/SQL.png';
import docker from '@assets/skills/docker.png';
import git from '@assets/skills/git.png';
import AWS from '@assets/skills/aws-1.png';
import tableau from '@assets/skills/tableau.png';
import tensorflow from '@assets/skills/tensor-flow.png';
import GPT from '@assets/skills/gpt.png';

import html from '@assets/skills/html.png';
import css from '@assets/skills/css.png';
import javascript from '@assets/skills/javascript.png';
import react from '@assets/skills/react.png';
import next from '@assets/skills/next.png';
import tailwind from '@assets/skills/tailwind.png';
import node from '@assets/skills/node-js.png';
import mongodb from '@assets/skills/mongodb.png';
import github from '@assets/skills/github.png';
import nginx from '@assets/skills/nginx.png';
import jenkins from '@assets/skills/jenkins.png';

import powerBI from '@assets/skills/power-bi.png';
import sciKit from '@assets/skills/scikit.svg';
import xgBoost from '@assets/skills/xgboost.svg';
import sciPy from '@assets/skills/scipy.svg';
import NLTK from '@assets/skills/NLTK.png';

import claude from '@assets/skills/claude.svg';
import langChain from '@assets/skills/langchain.svg';
import huggingFAce from '@assets/skills/hugging-face.svg';
import midwayJourney from '@assets/skills/midway-journey.svg';
import gemini from '@assets/skills/gemini.svg';
import dallE from '@assets/skills/dall-e.svg';
import runwayMl from '@assets/skills/runway-ml.svg';
import gradio from '@assets/skills/gradio.svg';

import { SkillSet } from '@components/local/hire-from-us/CandidateRoles';
import useMediaQuery from '@utils/hooks/useMediaQuery';

function Curriculum({ learningEssentials = [], sectionDescription, isWebCourse = false }) {
  const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);

  const track = learningEssentials[selectedTrackIndex];

  const modules = track.learningJourney[0].modules.data;

  const selectedModule = modules[selectedModuleIndex];

  const topics = selectedModule?.attributes.learningTopics;
  const moduleDuration = selectedModule?.attributes?.duration;

  const isMobile = useMediaQuery('(max-width: 767.98px)');

  useEffect(() => {
    setSelectedTrackIndex(0);
    setSelectedModuleIndex(0);
  }, [learningEssentials]);

  return (
    <div
      className="module flex flex-col items-center gap-y-[48px] bg-black px-[16px] pt-[44px] pb-[60px] md:px-[124px] md:pt-[80px] md:pb-[88px]"
      id="curriculum"
    >
      <div className="flex flex-col items-center gap-y-[16px]">
        <h2 className="hidden text-center font-satoshi-bold text-[36px] leading-none text-white md:block">
          Curriculum and{' '}
          <span className="relative -top-3 text-red underline underline-offset-[12px]">
            Learning Track
          </span>
        </h2>

        <h2 className="text-center font-satoshi-bold text-[30px] leading-9 text-white md:hidden">
          Our Learning{' '}
          <span className="relative -top-3 text-red underline underline-offset-[12px]">Track</span>
        </h2>

        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-2 md:text-[18px] md:leading-7">
          {sectionDescription}
        </p>
      </div>

      {learningEssentials.length > 1 && (
        <div className="no-scrollbar flex w-full grid-cols-3 gap-x-[32px] overflow-x-scroll pt-[51px] md:grid">
          {learningEssentials.map(
            ({ id, title, duration, description, learningJourney }, index) => (
              <TrackCard
                key={id}
                title={title?.split(' -')[0]}
                description={description}
                duration={duration}
                selected={index === selectedTrackIndex}
                iteration={Number(index + 1).toLocaleString('en-IN', { minimumIntegerDigits: 2 })}
                onClick={() => {
                  setSelectedTrackIndex(index);

                  setSelectedModuleIndex(0);
                }}
              />
            )
          )}
        </div>
      )}

      <div className="grid w-full gap-[32px] md:grid-cols-12">
        <div className="gap-y-[4px] md:col-span-6 md:gap-0">
          {modules?.map(({ id, attributes }, index) => (
            <ListLayout
              key={id}
              iteration={Number(index + 1).toLocaleString('en-IN', { minimumIntegerDigits: 2 })}
              title={attributes?.title}
              description={attributes?.description}
              selected={index === selectedModuleIndex}
              onClick={() => {
                if (isMobile) {
                  const selectedVal = selectedModuleIndex === index ? null : index;

                  setSelectedModuleIndex(selectedVal);

                  return;
                }

                setSelectedModuleIndex(index);
              }}
            >
              {index === selectedModuleIndex && (
                <TopicCard topics={topics || []} duration={moduleDuration} />
              )}
            </ListLayout>
          ))}
        </div>
        <div className="hidden md:col-span-6 md:block">
          <TopicCard topics={topics || []} duration={moduleDuration} />
        </div>
      </div>

      <CurriculumSkillSet isWebCourse={isWebCourse} />
    </div>
  );
}

export default Curriculum;

function CurriculumSkillSet({ isWebCourse = false }) {
  const dataScienceSkillSet = [
    {
      logo: python,
      skill: 'Python',
    },
    {
      logo: pandas,
      skill: 'Pandas',
    },
    {
      logo: numpy,
      skill: 'NumPy',
    },
    {
      logo: matplotlib,
      skill: 'Matplotlib',
    },
    {
      logo: SQL,
      skill: 'SQL',
    },
    {
      logo: tableau,
      skill: 'Tableau',
    },
    {
      logo: powerBI,
      skill: 'PowerBI',
    },
    {
      logo: git,
      skill: 'GIT',
    },
    {
      logo: github,
      skill: 'GitHub',
    },
    {
      logo: sciKit,
      skill: 'Scikit-Learn',
    },
    {
      logo: xgBoost,
      skill: 'XGBoost',
    },
    {
      logo: NLTK,
      skill: 'NLTK',
    },
    {
      logo: sciPy,
      skill: 'SciPy',
    },
    {
      logo: pytorch,
      skill: 'PyTorch',
    },
    {
      logo: tensorflow,
      skill: 'TensorFlow',
    },
    {
      logo: GPT,
      skill: 'Chat GPT',
    },
    {
      logo: claude,
      skill: 'Claude',
    },

    {
      logo: langChain,
      skill: 'LangChain',
    },
    {
      logo: huggingFAce,
      skill: 'Hugging Face',
    },
    {
      logo: midwayJourney,
      skill: 'MidJourney',
    },
    {
      logo: gemini,
      skill: 'Gemini',
    },
    {
      logo: dallE,
      skill: 'Dall-E',
    },
    {
      logo: runwayMl,
      skill: 'Runway ML',
    },
    {
      logo: gradio,
      skill: 'Gradio',
    },
  ];

  const webDevSkillSet = [
    {
      logo: html,
      skill: 'HTML5',
    },
    {
      logo: css,
      skill: 'CSS3',
    },
    {
      logo: javascript,
      skill: 'JavaScript',
    },
    {
      logo: react,
      skill: 'React JS',
    },
    {
      logo: next,
      skill: 'Next JS',
    },
    {
      logo: tailwind,
      skill: 'Tailwind CSS',
    },
    {
      logo: node,
      skill: 'NodeJs',
    },
    {
      logo: SQL,
      skill: 'SQL',
    },
    {
      logo: mongodb,
      skill: 'MongoDB',
    },
    {
      logo: git,
      skill: 'Git',
    },
    {
      logo: github,
      skill: 'GitHub',
    },
    {
      logo: nginx,
      skill: 'Nginx',
    },
    {
      logo: jenkins,
      skill: 'Jenkins',
    },
    {
      logo: docker,
      skill: 'Docker',
    },
    {
      logo: AWS,
      skill: 'AWS',
    },
    {
      logo: GPT,
      skill: 'Chat GPT',
    },
  ];

  return (
    <div className="flex w-full flex-col gap-y-[32px]">
      <h6 className="font-satoshi-bold text-[20px] leading-[30px] text-white">
        Skills you’ll acquire
      </h6>
      <SkillSet
        skillsSet={isWebCourse ? webDevSkillSet : dataScienceSkillSet}
        isWebCourse={isWebCourse}
      />
    </div>
  );
}
