import styles from './styles/cardStyles.module.css';
import { sectionScroll } from '../../shared/scetionScroll';
import { useEffect } from 'react';
import RecognizedBy from '@components/reuseable/RecognizedBy';
import DemoClassForm from '../courses/forms/demo-class-form/DemoClassForm';
import DarkLayout from '@components/shared/DarkLayout';
import { Primary } from 'stories/Button.stories';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import GlobalBrandingHeader from '@components/shared/forms/global-form/GlobalBrandingHeader';

export default function HeroCard({ data }) {
  let text = [
    'Cohorts',
    'Community',
    'Code',
    'Coaches',
    'Collaboration',
    'AlmaBetter',
    'AlmaBetter',
  ];

  const { heading, description, buttonText, buttonLink, heroImage } = data;
  useEffect(() => {
    let counter = 1;

    if (typeof window !== 'undefined') {
      let elem = document.getElementById('changeText');

      setInterval(change, 3000);

      function change() {
        elem.innerHTML = text[counter];
        counter++;
        if (counter >= text.length) {
          counter = 0;
        }
      }
    }
  }, []);

  return (
    <DarkLayout gridMode id="program-banner">
      <div className="container-default-1209px relative z-10">
        <div className="grid items-start gap-6 px-5 pb-20 pt-16 md:grid-cols-2 md:items-center md:px-0">
          <div className="flex flex-col justify-evenly gap-y-[24px] text-center md:items-start md:text-left">
            <div className="mx-auto md:mx-0">
              <h2 className="text-[38px] text-white lg:text-5xl">{heading}</h2>
              <h2
                id="changeText"
                className={`mx-auto mb-5 mt-1 w-fit text-[38px] text-red md:mx-0 lg:text-5xl ${styles.animate} ${styles.animate__style}`}
              >
                Cohorts
              </h2>
            </div>
            <p className="flex flex-col gap-y-[16px] font-satoshi-regular text-[16px] leading-6 text-white md:text-[18px] md:leading-7">
              <span className="flex items-center gap-x-[8px]">
                <IoIosCheckmarkCircleOutline className="mt-1 text-red" size={24} />{' '}
                {description.split('.')[0]}
              </span>

              <span className="flex items-center gap-x-[8px]">
                <IoIosCheckmarkCircleOutline className="mt-1 text-red" size={24} />{' '}
                {description.split('.')[1]}
              </span>

              <span className="flex items-center gap-x-[8px]">
                <IoIosCheckmarkCircleOutline className="mt-1 text-red" size={24} />{' '}
                {description.split('.')[2]}
              </span>
            </p>

            <Primary
              onClick={(e) => sectionScroll({ e, ref: '#featuredCourses', topValue: 50 })}
              label={buttonText}
              size={'large'}
              customStyle={'self-center md:self-start'}
            />
          </div>
          <div className="md:self-start md:pl-8">
            <GlobalBrandingHeader>
              <DemoClassForm
                onBoardingFormStyle={'slim-scroll h-fit md:max-h-[75vh]'}
                container={'!rounded-t-none'}
              />
            </GlobalBrandingHeader>
          </div>
        </div>
      </div>
    </DarkLayout>
  );
}
