import Link from 'next/link';
import Image from 'next/image';
import useStorage from '../../../utils/hooks/useStorage';

const Community = ({ AlmaCommunity }) => {
  // get utm from storage
  const { getItem } = useStorage();
  const currentUtm = getItem('utm') && JSON.parse(getItem('utm'));
  // set query string for redirect
  let queryString = '';
  for (const property in currentUtm) {
    queryString += `&${property}=${currentUtm[property]}`;
  }
  let href = '';
  if (AlmaCommunity?.buttonLink) {
    if (queryString) {
      href = `${AlmaCommunity?.buttonLink}?${queryString}`;
    } else {
      href = `${AlmaCommunity?.buttonLink}`;
    }
  }
  return (
    <>
      <div className="relative mt-20 mb-20 flex flex-col items-center justify-start rounded-lg">
        <div className="hidden md:block" style={{ height: '32rem', width: '100px' }}>
          <Image src="/assets/images/Community.png" alt="" loading="eager" layout="fill" />
        </div>
        <div className="block w-full md:hidden" style={{ height: '40rem', width: '100px' }}>
          <Image
            src="/assets/images/community/Group 1000002484 (2).png"
            alt=""
            loading="lazy"
            layout="fill"
          />
        </div>
        <div
          className="absolute my-auto mr-auto ml-auto mt-[11rem] flex flex-col items-center justify-center md:mt-8"
          style={{ zIndex: '1' }}
        >
          <h2 className="text-center font-satoshi-bold md:mt-5 md:w-[60%]">
            {AlmaCommunity.Community.heading}
          </h2>
          <p className="overflow-wrap text-para mt-6 w-[80%] text-center font-satoshi-medium text-gray-300 lg:w-[55%]">
            {AlmaCommunity.Community.description}
          </p>
          <Link href={href} passHref>
            <a>
              <button className="mt-6 rounded bg-red px-4 py-3 font-satoshi-medium uppercase text-white md:py-3 md:px-8">
                {AlmaCommunity.buttonText}
              </button>
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Community;
