import React from 'react';

function TrackCard({
  title = '',
  duration = '',
  description = '',
  iteration,
  selected = false,
  onClick,
}) {
  return (
    <div
      className={`group relative flex h-[116px] cursor-pointer items-center overflow-hidden rounded-2xl bg-[#1A1A1A] pl-[24px] transition duration-75 ease-in-out hover:bg-[#3D3D3D] hover:pr-[20px] md:h-[148px] ${
        selected ? 'bg-[#3D3D3D] pr-[20px]' : ''
      }`}
      style={{ minWidth: '300px' }}
      onClick={onClick}
    >
      <p
        className={`absolute top-16 -left-16 font-satoshi-bold text-[227px] text-[#FFFFFF05] group-hover:text-[#00000040] ${
          selected ? 'text-[#00000040]' : ''
        }`}
      >
        {iteration}
      </p>
      <div className="z-1 relative flex w-full flex-col items-start gap-y-[16px]">
        <div
          className={`flex w-full flex-col items-start gap-y-[4px] group-hover:flex-row group-hover:items-center group-hover:justify-between ${
            selected ? '!flex-row items-center justify-between' : ''
          }`}
        >
          <h3
            className={`font-satoshi-bold text-[16px] leading-6 text-[#EAECF0] group-hover:text-white md:text-[20px] md:leading-[30px] ${
              selected ? '!text-white' : ''
            }`}
          >
            {title}
          </h3>
          <p className="font-satoshi-medium text-[12px] leading-[18px] text-red md:text-[16px] md:leading-6">
            {duration}
          </p>
        </div>
        <p
          className={`z-1 relative hidden font-satoshi-regular text-[12px] leading-[18px] text-[#EAECF0] group-hover:line-clamp-2 md:text-[14px] md:leading-[20px] ${
            selected ? 'line-clamp-2' : 'hidden'
          }`}
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export default TrackCard;
