import { getImgUrl } from '@utils/functions/getImgUrl';
import React from 'react';
import iitLogo from '@assets/new-iit-short-logo-black.png';
import Image from 'next/image';

const PartnersLogo = ({ data, className }) => {
  return (
    <div className={`${className} flex items-center justify-center gap-x-2 gap-y-4`}>
      {data?.map(({ Icon, Name }, i) => (
        <React.Fragment key={i}>
          <Image src={getImgUrl(Icon)} width={120} height={40} objectFit="contain" />
          {data?.length - 1 === i ? '' : <p className="font-satoshi-bold font-bold ">+</p>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PartnersLogo;
