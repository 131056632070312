import React from 'react';
import underline from '@assets/courses/svg/section-title-underline-decoration.svg';
import Image from 'next/image';

const SectionTitle = ({ title, subTitle, strikePosition = 'right', className }) => {
  return (
    <div className={`${className} relative mb-12 w-fit`}>
      {title && <h2 className="header-h2 !font-satoshi-medium font-semibold leading-7">{title}</h2>}
      {subTitle && (
        <h3 className="header-h2 !font-satoshi-medium font-semibold leading-7">{subTitle}</h3>
      )}
      <div
        className={
          strikePosition === 'right'
            ? 'absolute left-0 -bottom-[1.4em] md:right-0'
            : 'absolute left-0 -bottom-[1.4em]'
        }
      >
        <Image
          src={underline}
          objectFit="contain"
          width={150}
          height={14}
          alt="underline"
          className="h-3.5 w-36"
        />
      </div>
    </div>
  );
};

export default SectionTitle;
