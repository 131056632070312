import SuccessCard from './SuccessCard';
// import Swiper core and required modules
import { Pagination, Scrollbar, A11y, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Primary } from 'stories/Button.stories';
import NewSuccessCard from './NewSuccessCard';
import SliderComponent from '@components/shared/CustomSlider';
import DemoClassFormButton from '@local/courses/forms/demo-class-form/DemoClassFormButton';

const SuccessStories = ({
  stories,
  courseCTAs = {},
  degree,
  DownloadBrouchure,
  heading = '',
  description = '',
  isWeb3Course = false,
}) => {
  const { freeTrialLink, freeTrialText } = courseCTAs;

  return (
    <>
      {!degree ? (
        <div id="career Impact" className="module mt-20 not-first:child:mb-8">
          <div className="mx-4 flex flex-col gap-y-2 lg:mx-20">
            <h2 className="header-h2 font-satoshi-bold font-bold ">
              {heading || 'Transforming careers with guaranteed outcomes'}
            </h2>
            <p className="outline- text-body mb-8">
              {description || 'We don’t just give certification but outcomes!'}
            </p>
          </div>
          <Swiper
            modules={[Pagination, A11y, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 55,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 55,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            navigation
            className="!mx-4 !pb-16 lg:!mx-14 lg:!px-6"
            pagination={{ clickable: true }}
          >
            {stories
              ?.sort((a, b) => (a.attributes.rank ?? Infinity) - (b.attributes.rank ?? Infinity))
              ?.map((story) => (
                <SwiperSlide key={stories.id}>
                  <SuccessCard story={story} />
                </SwiperSlide>
              ))}
          </Swiper>

          <div className="mx-auto flex w-10/12 flex-wrap items-center justify-center gap-y-6 gap-x-6 md:w-6/12 md:flex-nowrap">
            {isWeb3Course && freeTrialText ? (
              <Primary
                linkCTA
                href={freeTrialLink}
                target="_blank"
                label={freeTrialText}
                data-cta={degree ? 'degree' : 'explore'}
                size="2xl"
                capitalCase
              />
            ) : (
              <DemoClassFormButton />
            )}

            {DownloadBrouchure && <DownloadBrouchure outline />}
          </div>
        </div>
      ) : (
        <NewSuccessStories stories={stories} />
      )}
    </>
  );
};

export default SuccessStories;

function NewSuccessStories({ stories = [] }) {
  return (
    <div
      className="module !flex w-full flex-col items-center justify-center gap-y-[80px] overflow-hidden px-[16px] pt-[32px] pb-[44px] md:gap-y-[100px] md:px-[124px] md:pt-[80px] md:pb-[118px]"
      id="career Impact"
    >
      <div className="z-1 relative flex flex-col items-center gap-y-[16px]">
        <h2 className="font-satoshi-bold text-[30px] leading-9 md:text-[36px] md:leading-none">
          Success{' '}
          <span className="relative -top-2 text-red underline underline-offset-8">Stories</span>
        </h2>
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          Meet our graduates who have not only secured new and better jobs but have also experienced
          significant salary hikes.
        </p>
      </div>

      <div className="w-full">
        <SliderComponent
          swiperOptions={{
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
                slidesPerGroup: 2,
              },
            },
          }}
          buttonStyle={{
            size: 24,
            buttonClass: 'col-span-6 rounded-full',
            leftButton: 'place-self-end',
            rightButton: 'place-self-start',
          }}
        >
          {stories.map(({ id, attributes }) => (
            <NewSuccessCard studentData={attributes} key={id} />
          ))}
        </SliderComponent>
      </div>

      <div className="z-1 relative flex w-full items-center justify-center md:hidden">
        <Primary linkCTA href={'/success-stories'} label={'Read More'} size={'large'} />
      </div>
    </div>
  );
}
