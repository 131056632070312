import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

function Tooltip({ content, children, arrowPosition = 'bottom' }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setIsOpen(false)}
      >
        <div className="absolute z-10">
          <div className="bg-red-50 rounded-lg p-4 text-gray-300 shadow-lg">{content}</div>
          <div
            className={`bg-red-50 absolute z-[-1] h-10 w-10 rotate-45  transform shadow-lg ${
              arrowPosition === 'top'
                ? 'bottom-[-1px] left-[10px]'
                : arrowPosition === 'right'
                ? 'left-[-1px] top-[10px]'
                : arrowPosition === 'bottom'
                ? 'top-[-1px] left-[10px]'
                : arrowPosition === 'left'
                ? 'right-[-1px] top-[10px]'
                : ''
            }`}
          />
        </div>
      </Transition>
    </div>
  );
}

export default Tooltip;
