import LearningJourneyContainer from './LearningJourneyContainer';
import ModuleTabs from './ModuleTabs';
import Electives from './Elective';
import NextCohort from '../shared/NextCohort';
import { useRouter } from 'next/router';
import { COURSE_TYPE, COURSE_TYPE_SLUG_MAPPING, PLAN_TYPE } from '@lib/constants';
import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { getImgUrl } from '@utils/functions/getImgUrl';
import { Primary } from 'stories/Button.stories';
import Image from 'next/image';

const Tabs = ({ data, courseCTAs, degree, AlmaX = false }) => {
  let [categories] = useState(
    data?.reduce((prev, curr) => {
      prev[curr.title] = curr;
      return prev;
    }, {})
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div>
      <Tab.Group>
        <Tab.List className="flex flex-wrap gap-4 space-x-1 rounded-xl p-1">
          {Object.keys(categories).map((category, index) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'rounded-full px-3 py-2 font-satoshi-medium text-xs font-medium   leading-5 text-white outline-none lg:text-sm',
                  selected ? 'bg-red text-white shadow' : 'bg-white text-black'
                )
              }
            >
              <p className="flex flex-col items-start px-4 text-left capitalize  leading-5 md:px-6">
                <span>Specialization {index + 1}</span>
                <span className="line-clamp-1 md:line-clamp-2">{category}</span>
              </p>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((items, idx) => (
            <Tab.Panel key={idx} className={'rounded-xl py-4'}>
              <>
                {items?.modules?.data?.length > 0 && (
                  <ModuleTabs
                    courseCTAs={courseCTAs}
                    items={items?.modules?.data}
                    degree={degree}
                    AlmaX={AlmaX}
                  />
                )}
                {items?.electives?.data?.length > 0 && (
                  <Electives elective={electives?.data} degree={degree} />
                )}
              </>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

const DegreeJourney = ({ courseCTAs, courseData, degree, AlmaX, isWeb3Course = false }) => {
  const { upcomingBatch, learningJourneyElective } = courseData;
  const { freeTrialLink, freeTrialText } = courseCTAs;

  const AlmaXData = courseData?.AlmaX || {};
  const AlmaXEligibility = AlmaXData?.eligibility?.length > 0;

  return (
    <div className="relative  overflow-hidden ">
      {!degree && AlmaX && AlmaXEligibility && (
        <>
          <LearningJourneyContainer step={1} title={'AlmaX Eligibility Requirements'}>
            <section className="mb-12 grid gap-6 md:grid-cols-3">
              {AlmaXData?.eligibility?.map?.(({ title, description, icon }) => (
                // eslint-disable-next-line react/jsx-key
                <section
                  key={title}
                  className="gradient-border-dark flex flex-col items-center justify-center rounded-lg p-8 text-center"
                >
                  {icon && (
                    <Image
                      src={getImgUrl(icon)}
                      width={76}
                      height={76}
                      objectFit="contain"
                      alt="icon"
                    />
                  )}
                  <h4 className="font-satoshi-bold text-[20px] leading-8 text-white">{title}</h4>
                  <p className="font-satoshi-medium text-[16px] leading-6 text-gray-400 line-clamp-2">
                    {description}
                  </p>
                </section>
              ))}
            </section>
          </LearningJourneyContainer>
        </>
      )}

      {!AlmaX &&
        learningJourneyElective?.map((item, index, arr) => (
          <LearningJourneyContainer
            key={item.id}
            step={index + 1}
            title={item.title}
            duration={item.duration}
            description={item.description}
          >
            {item?.learningJourney?.length > 1 || item?.isSpecialisationTrack ? (
              <Tabs data={item?.learningJourney} courseCTAs={courseCTAs} degree={degree} />
            ) : (
              item?.learningJourney?.map(({ modules, electives }, index) => (
                <>
                  {modules?.data?.length > 0 && (
                    <ModuleTabs
                      key={index}
                      courseCTAs={courseCTAs}
                      items={modules?.data}
                      degree={degree}
                      AlmaX={AlmaX}
                      isWeb3Course={isWeb3Course}
                    />
                  )}
                  {electives?.data?.length > 0 && (
                    <Electives elective={electives?.data} degree={degree} />
                  )}
                </>
              ))
            )}

            {!AlmaX && index === arr.length - 1 && (
              <div className=" absolute left-0 -bottom-6  right-1/2 flex h-8 w-8  -translate-y-1/2 items-center justify-center  rounded-full bg-red text-white md:h-12 md:w-12">
                <p className="font-satoshi-bold text-lg font-bold  md:text-4xl">{arr.length + 1}</p>
              </div>
            )}
          </LearningJourneyContainer>
        ))}

      {!degree && AlmaX && (
        <>
          {AlmaXData?.learningJourney?.map(({ modules, electives, title }, index, arr) => (
            <LearningJourneyContainer
              step={AlmaXEligibility ? index + 2 : index + 1}
              key={title}
              title={title}
            >
              {modules?.data?.length > 0 && (
                <ModuleTabs
                  courseCTAs={courseCTAs}
                  items={modules?.data}
                  isWeb3Course={isWeb3Course}
                />
              )}
              {electives?.data?.length > 0 && (
                <Electives elective={electives?.data} degree={degree} />
              )}
            </LearningJourneyContainer>
          ))}
        </>
      )}

      {AlmaX && AlmaXData?.almaxCarrierServices && (
        <>
          <LearningJourneyContainer
            hideJourney={degree}
            step={
              AlmaXEligibility
                ? AlmaXData?.learningJourney?.length + 2
                : AlmaXData?.learningJourney?.length + 1
            }
            title={degree ? '' : `AlmaX Career Services`}
          >
            <section className="mb-12 grid gap-6 md:grid-cols-3">
              {AlmaXData?.almaxCarrierServices?.map(({ title, description, icon }) => (
                // eslint-disable-next-line react/jsx-key
                <section
                  key={title}
                  className="gradient-border-dark flex flex-col items-center justify-center rounded-lg p-8 text-center"
                >
                  {icon && (
                    <Image
                      src={getImgUrl(icon)}
                      width={76}
                      height={76}
                      objectFit="contain"
                      alt="icon"
                    />
                  )}
                  <h4 className="font-satoshi-bold text-[20px] leading-8 text-white">{title}</h4>
                  <p className="font-satoshi-medium text-[16px] leading-6 text-gray-400 line-clamp-2">
                    {description}
                  </p>
                </section>
              ))}
            </section>
          </LearningJourneyContainer>
        </>
      )}

      {!degree && AlmaX && AlmaXData?.almaxCarrierServices && (
        <LearningJourneyContainer
          step={
            AlmaXEligibility
              ? AlmaXData?.learningJourney?.length + 3
              : AlmaXData?.learningJourney?.length + 2
          }
          end
          title={`Land your dream job at one of the leading tech companies`}
        >
          <div>
            <img
              src="/assets/courses/companies.png"
              className="gradient-border-gray-145 w-full rounded-2xl object-contain p-8"
              alt="companies"
              loading="lazy"
            />
          </div>

          {courseData?.planType === PLAN_TYPE.MASTERS_DEGREE && (
            <div
              className="mx-auto mt-6 w-fit rounded-md bg-green-cyan
p-3 py-1 font-satoshi-medium text-lg text-blue-midnight"
            >
              Get upto 50% Scholarship
            </div>
          )}
          <p className="mt-6 mb-6 text-center font-satoshi-medium text-white">
            Go to Program Fee section to know Career Services Fee.
          </p>

          <div className="mx-auto w-fit pb-4">
            {!isWeb3Course && (
              <div className="mb-6 flex justify-center">
                <NextCohort data={upcomingBatch} />
              </div>
            )}
            {freeTrialText && (
              <Primary
                linkCTA
                href={freeTrialLink}
                target="_blank"
                data-cta={!degree && 'explore'}
                label={freeTrialText}
                disabled={isWeb3Course}
                size="large"
              />
            )}
          </div>
        </LearningJourneyContainer>
      )}
    </div>
  );
};

export default DegreeJourney;
