import { getImgUrl } from '@utils/functions/getImgUrl';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { IoIosTrendingUp } from 'react-icons/io';
import { MdArrowForward } from 'react-icons/md';
import { CustomBadge } from 'stories/Badge.stories';

function NewSuccessCard({ studentData = null, customStyle = '' }) {
  if (!studentData) return <></>;

  const {
    studentName,
    tagline,
    studentDesignation,
    hike,
    avatar,
    companyLogo,
    linkedinUrl,
    slug,
    oldCompanyDetails,
  } = studentData || {};

  return (
    <div
      className="flex h-full flex-col items-start gap-y-[8px] rounded-lg border border-[#F2F4F7] bg-white"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
    >
      <div className="newSuccessCard--redBackground relative flex w-full items-end justify-center pt-[16px]">
        <div className="absolute top-[16px] left-[16px]">
          {hike && (
            <CustomBadge
              label={`${hike}% Hike`}
              backgroundColor={'white'}
              customStyle="!px-[16px] py-[4px] border-0 flex items-center gap-x-[8px] !text-red"
            >
              <IoIosTrendingUp size={16} />
            </CustomBadge>
          )}
        </div>
        <Image
          src={getImgUrl(avatar)}
          objectFit="contain"
          width={376}
          height={240}
          loading="lazy"
        />
      </div>
      <div className="flex h-full flex-col items-start gap-y-[20px] p-[16px]">
        <div className="flex flex-col gap-y-[16px]">
          <div className="grid w-full grid-cols-7 items-center gap-x-[12px]">
            <div className="col-span-3 flex flex-col items-center gap-y-[12px] place-self-start">
              <CustomBadge
                label={oldCompanyDetails?.designation || 'Fresher'}
                labelColor={'#DC6803'}
                backgroundColor={'#FFFAEB'}
                borderColor={'#FEDF89'}
                customStyle="line-clamp-1 !px-[16px] py-[4px] border"
              />

              {oldCompanyDetails?.logo?.data ? (
                <Image
                  src={getImgUrl(oldCompanyDetails.logo)}
                  width={95}
                  height={28}
                  objectFit="cover"
                  loading="lazy"
                />
              ) : (
                <p className="text-center font-satoshi-bold text-[16px] leading-6">
                  {oldCompanyDetails?.name || 'Graduate'}
                </p>
              )}
            </div>

            <MdArrowForward size={24} />

            <div className="col-span-3 flex flex-col items-center gap-y-[12px] place-self-end">
              <CustomBadge
                label={studentDesignation}
                labelColor={'#067647'}
                backgroundColor={'#ECFDF3'}
                borderColor={'#ABEFC6'}
                customStyle="line-clamp-1 !px-[16px] py-[4px] border"
              />
              {/* <p className="text-center font-satoshi-bold text-[16px] leading-6">{placedAt}</p> */}
              <Image
                src={getImgUrl(companyLogo)}
                width={95}
                height={28}
                objectFit="cover"
                loading="lazy"
              />
            </div>
          </div>

          <div>
            <div className="grid grid-cols-8 items-center gap-x-[16px]">
              <h3 className="col-span-7 text-left font-satoshi-bold text-[24px] leading-8">
                {studentName}
              </h3>

              <a
                href={linkedinUrl}
                target="_blank"
                className="z-1 relative"
                onClick={(e) => e.stopPropagation()}
              >
                <BsLinkedin size={28} className="rounded text-[#0A66C2]" />
              </a>
            </div>
            <p className="text-left font-satoshi-regular text-[16px] leading-6 text-gray-11 line-clamp-3">
              {tagline}
            </p>
          </div>
        </div>
        <Link href={`/success-stories/${slug}`} as={`/success-stories/${slug}`} passHref>
          <a
            target="_blank"
            className="mt-auto font-satoshi-bold text-[16px] leading-6 text-[#0A66C2]"
          >
            Read More
          </a>
        </Link>
      </div>
    </div>
  );
}

export default NewSuccessCard;
