// src/components/BackToTopButton.js
import React, { useState, useEffect } from 'react';
import { RiArrowUpSLine } from 'react-icons/ri';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="hover:bg-blue-700 fixed bottom-7 left-5 z-50 rounded-sm bg-white py-2 px-3 font-satoshi-medium font-semibold text-black shadow-md transition duration-300 ease-in-out md:bottom-5"
        >
          <RiArrowUpSLine size={27} />
        </button>
      )}
    </>
  );
};

export default BackToTop;
