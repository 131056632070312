import React, { useContext, useState } from 'react';
import { CourseDataContext } from 'pages/courses/[courseSlug]';
import { Secondary } from 'stories/Button.stories';

const FAQ = ({ faqs, heading, degree }) => {
  const [active, setActive] = useState(faqs[0]);
  const { courseInterest } = useContext(CourseDataContext);

  const handleItemClick = (index) => {
    setActive(index);
  };

  return (
    <div
      className="module flex flex-col items-center gap-y-[40px] bg-[#FCFCFD] py-[64px] px-[16px] md:py-[96px] md:px-[124px]"
      id="FAQs"
    >
      <div className="flex flex-col items-center gap-y-[16px] pb-[8px]">
        {degree ? (
          <h2 className="text-center font-satoshi-bold text-[30px] leading-9 md:text-[36px] md:leading-none">
            Frequently asked{' '}
            <span className="relative -top-3 text-red underline underline-offset-[12px]">
              Questions
            </span>
          </h2>
        ) : (
          <h2 className="text-center font-satoshi-bold text-[30px] leading-9 md:text-[36px] md:leading-none">
            Frequently asked questions
          </h2>
        )}
        <p className="text-center font-satoshi-regular text-[18px] leading-7 text-gray-400">
          Everything you need to know about the courses, tuition fees, etc.
        </p>
      </div>

      <div className="flex w-full flex-col items-center gap-y-[40px]">
        <div className="flex flex-wrap items-center justify-center gap-[16px]">
          {faqs.map((item) => {
            return (
              <Secondary
                label={item.category}
                key={item.id}
                onClick={() => handleItemClick(item)}
                size={'small'}
                selected={active.id === item.id}
              />
            );
          })}
        </div>

        <Faq faq={active?.faq} courseInterest={courseInterest} />
      </div>
    </div>
  );
};

export const Faq = ({ faq, courseInterest }) => {
  const [isOpen, setIsOpen] = useState(0);

  return (
    <div className="flex w-full flex-col gap-y-[32px]">
      {faq?.map(({ question, answer }, index) => (
        <details
          key={index}
          open={!index}
          onClick={() => {
            setIsOpen(index);
          }}
          className="flex w-full flex-col gap-y-[8px]"
        >
          <summary>
            <h3
              className={`font-satoshi-medium font-satoshi-medium text-[18px] font-medium  leading-7`}
            >
              {question}
            </h3>
          </summary>
          <p className={`font-satoshi-regular text-[16px] leading-6 text-gray-navigation`}>
            {answer}
          </p>
        </details>
      ))}
    </div>
  );
};

export default FAQ;
