import MarkDownPreview from '@components/reuseable/MarkDownPreview';
import React from 'react';

function Disclaimer({ notes = '' }) {
  return (
    <div
      className={`mx-[16px] mb-[30px] mt-[24px] rounded-lg border border-gray-2 bg-[#F9FAFB] p-[24px] !font-satoshi-regular md:mx-[124px] md:mb-[80px]`}
    >
      <MarkDownPreview content={notes} />
    </div>
  );
}

// shadow-[rgba(60,64,67,0.15)_0_4px_8px_4px]

export default Disclaimer;
