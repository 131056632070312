import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { FiMinus } from 'react-icons/fi';
import cn from 'classnames';

function ListLayout({ iteration, title, description, selected = false, onClick, children }) {
  return (
    <>
      <div
        className={cn(
          'group flex cursor-pointer items-center justify-between gap-x-[8px] px-[24px] py-[16px]',
          {
            'gradient-border-dark rounded-lg': selected,
          }
        )}
        onClick={onClick}
      >
        <div className="flex items-center gap-x-[24px]">
          <span className="font-satoshi-medium text-[20px] leading-[30px] text-red">
            {iteration}
          </span>

          <div className="flex flex-col gap-y-[4px]">
            <h4
              className={cn(
                'font-satoshi-medium text-[18px] leading-7 text-gray-400 group-hover:text-white',
                { '!text-white': selected }
              )}
            >
              {title}
            </h4>

            {description && (
              <p
                className={cn(
                  'font-satoshi-normal text-[14px] leading-5 text-gray-400 group-hover:text-gray-2 md:text-[16px] md:leading-6',
                  { '!text-gray-2': selected }
                )}
              >
                {description}
              </p>
            )}
          </div>
        </div>

        <MdOutlineKeyboardArrowRight
          size={32}
          className={cn('text-gray-400 group-hover:text-white', {
            'hidden md:block md:!text-white': selected,
            block: !selected,
          })}
        />

        <FiMinus
          size={32}
          className={cn({ 'text-white md:hidden': selected, hidden: !selected })}
        />
      </div>

      <div className="w-full pt-[16px] md:hidden">{children}</div>
    </>
  );
}

export default ListLayout;
