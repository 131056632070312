import React, { useState } from 'react';
import { HierarchyPrimary } from 'stories/Button.stories';
import { FaArrowRight } from 'react-icons/fa';
import Image from 'next/image';
import { getImgUrl } from '@utils/functions/getImgUrl';
import SliderComponent from '@components/shared/CustomSlider';
import Gamified from './Gamified';

function ProjectAndAssesment({ projects = [], pgCourse = false, description }) {
  const [selectedCard, setSelectedCard] = useState(0);

  const cardList = [
    {
      title: 'Capstone Projects',
      overview: 'Undertake Industry Level Projects in teams',
      link: '#',
      imagePath: '/assets/capstone-projects.png',
    },

    {
      title: 'Mock Interviews',
      overview: 'Interview Prep through 20+ live mock interviews with real-life examples',
      link: '#',
      imagePath: '/assets/mock-interviews.png',
    },

    ...(pgCourse
      ? [
          {
            title: 'Gamified experiments',
            overview: 'Learn complex concepts through games & experiments at AlmaBetter Labs',
            link: '#',
            urls: [
              'https://www.desmos.com/calculator/obtxebhtm4',
              'https://master.d3gu2k0dw5eqjo.amplifyapp.com/Seeing-Theory/probability-distributions/index.html#section1',
              'https://www.desmos.com/calculator/fpovzvinqt',
            ],
          },
        ]
      : []),
  ];

  return (
    <div className="flex flex-col items-center gap-y-[48px] bg-[#FCFCFD] px-[16px] pt-[40px] pb-[60px] md:gap-y-[80px] md:px-[124px] md:pt-[80px] md:pb-[112px]">
      <div className="flex flex-col items-center gap-y-[16px]">
        <h2 className="text-center font-satoshi-medium font-satoshi-medium text-[30px] font-semibold font-bold leading-9 md:font-satoshi-bold md:font-satoshi-bold md:text-[36px]  md:leading-none">
          Hand-on Learning &{' '}
          <span className="relative -top-2 text-red underline underline-offset-[12px]">
            Projects
          </span>
        </h2>
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-gray-400 md:text-[18px] md:leading-7">
          {description}
        </p>
      </div>
      <div className="grid items-center gap-x-[64px] gap-y-[48px] md:grid-cols-2">
        <div className="flex flex-col items-start">
          {cardList.map((item, index) => (
            <DetailCard
              key={index}
              {...item}
              selected={index === selectedCard}
              onClick={() => setSelectedCard(index)}
            />
          ))}
        </div>

        <div className="order-first w-full overflow-hidden md:order-none">
          {selectedCard === 0 && (
            <SliderComponent
              navigation={false}
              swiperOptions={{
                grid: {
                  rows: 1,
                  fill: 'row',
                },

                breakpoints: {
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                    grid: {
                      rows: 2,
                    },
                  },
                },
              }}
              paginate
              paginationClass="flatted-red-pagination"
              paginationLayout="col-span-12 flex items-center gap-x-[8px] cursor-pointer justify-center"
              spaceFromCarousel="24px"
            >
              {projects.map((project) => (
                <ProjectCard key={project.id} {...project} />
              ))}
            </SliderComponent>
          )}

          {selectedCard === 1 && (
            <img src={cardList[selectedCard].imagePath} alt={cardList[selectedCard].title} />
          )}

          {selectedCard === 2 && <Gamified urls={cardList[selectedCard].urls} />}
        </div>
      </div>
    </div>
  );
}

export default ProjectAndAssesment;

function DetailCard({ title, overview, link, selected = false, onClick }) {
  return (
    <div
      className={`flex flex-col items-start gap-y-[20px] transition-all duration-150 ease-in-out ${
        selected ? 'border-red' : 'border-[#F2F4F7]'
      } w-full cursor-pointer border-l-4 py-[16px] pl-[24px]`}
      onClick={onClick}
    >
      <div className="flex flex-col items-start gap-y-[8px]">
        <h3 className="font-satoshi-bold text-[24px] leading-8">{title}</h3>
        <p className="font-satoshi-normal text-[18px] leading-7 text-gray-navigation">{overview}</p>
      </div>
      <HierarchyPrimary label={'Learn More'} size={'large'} linkCTA href={link}>
        <FaArrowRight size={20} />
      </HierarchyPrimary>
    </div>
  );
}

function ProjectCard({ projectImage, projectTitle = '' }) {
  return (
    <div className="flex h-full flex-col items-center gap-y-[24px] rounded-lg border border-gray-2 bg-white p-[24px]">
      <div className="flex flex-col flex-col items-center gap-y-[16px]">
        <Image
          src={getImgUrl(projectImage)}
          width={120}
          height={40}
          objectFit="contain"
          loading="lazy"
        />
        <h4 className="text-center font-satoshi-medium font-satoshi-medium text-[14px] font-medium  leading-[20px]">
          {projectTitle}
        </h4>
      </div>
      <HierarchyPrimary
        linkCTA
        target={'_blank'}
        href={
          'https://grow.almabetter.com/auth/signup?mastersDSInterest=true&redirectTo=https://grow.almabetter.com/masters-ds/projects'
        }
        label={'Start Now'}
        size="small"
      >
        <FaArrowRight size={16} />
      </HierarchyPrimary>
    </div>
  );
}
