import Image from 'next/image';
import { getImgUrl } from '@utils/functions/getImgUrl';
import NewSectionTitle from '@components/shared/NewSectionTitle';

const FlexibleFeatures = ({
  features = {},
  DownloadBrouchure,
  DownloadPlacementReport,
  isWeb3Course = false,
}) => {
  const { metrics = [] } = features || {};

  return (
    <div
      className="features flex flex-col gap-y-[60px] bg-[#FCFCFD] py-[40px] px-[16px] md:py-[80px] md:px-[124px]"
      id="6"
    >
      <NewSectionTitle
        text={'A Flexible Program Created'}
        highlightedText={'Just for you!'}
        underlineStyle={'capitalize underline-offset-[12px]'}
      />
      <section className="grid gap-[48px] md:grid-cols-2">
        {metrics?.map(({ title, description, icon }) => (
          <div
            key={title}
            className="grid h-full grid-cols-5 items-center gap-[24px] rounded-lg border border-gray-2 bg-white p-[24px]"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
            }}
          >
            <div>
              <Image
                objectFit="contain"
                src={getImgUrl(icon)}
                height={100}
                width={100}
                alt="icon"
              />
            </div>
            <div className="col-span-4 flex flex-col gap-y-[8px]">
              <h3 className="font-satoshi-bold text-[20px] leading-7">{title}</h3>
              <p className="font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
                {description}
              </p>
            </div>
          </div>
        ))}
      </section>

      <div className="mt-8 flex flex-wrap items-center justify-center gap-x-10 gap-y-4">
        {DownloadBrouchure && <DownloadBrouchure disabled={isWeb3Course} capitalCase={false} />}

        {DownloadPlacementReport && (
          <DownloadPlacementReport outline disabled={isWeb3Course} capitalCase={false} />
        )}
      </div>
    </div>
  );
};
export default FlexibleFeatures;
