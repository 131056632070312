import 'react-multi-carousel/lib/styles.css';
import { Primary } from 'stories/Button.stories';
import { FaArrowRight } from 'react-icons/fa';
import SliderComponent from './CustomSlider';
import { getImgUrl } from '@utils/functions/getImgUrl';
import Image from 'next/image';

// awards
import awardOne from '@assets/award-1.png';
import awardTwo from '@assets/award-2.png';
import awardThree from '@assets/award-3.png';

export default function AwardsAndRecognition({
  pressHighlights,
  showRecognization = false,
  degree = false,
}) {
  if (!pressHighlights || !pressHighlights?.attributes?.highlights?.length) return null;

  return (
    <div
      className={`flex w-full flex-col gap-y-[64px] px-[16px] pt-[40px] pb-[67px] md:px-[123px] md:py-[138px] ${
        showRecognization ? 'bg-[#FCFCFD]' : ''
      }`}
    >
      <div className="flex flex-col items-center gap-y-[48px]">
        <h2 className="text-center font-satoshi-bold text-[30px] capitalize leading-9 md:text-[36px] md:leading-none">
          AlmaBetter Awards &{' '}
          <span className="relative -top-2 text-red underline underline-offset-[12px]">
            Recognition
          </span>
        </h2>
        <div className="w-full">
          <SliderComponent
            swiperOptions={{ slidesPerView: 1, spaceBetween: 10 }}
            buttonStyle={{
              size: 24,
              buttonClass: 'col-span-6 rounded-full !text-[#667085]',
              leftButton: 'place-self-end',
              rightButton: 'place-self-start',
            }}
          >
            {pressHighlights.attributes.highlights.map((highlight) => (
              <NewsCard key={highlight.id} {...highlight} />
            ))}
          </SliderComponent>
        </div>
      </div>
      {showRecognization && <RecognitionCertificate />}
    </div>
  );
}

function NewsCard({ thumbnail, description, link }) {
  return (
    <div className="flex w-full flex-col items-center items-center justify-between rounded-lg border border-[#F2F4F7] bg-white md:flex-row">
      <div className="relative h-[324px] w-[406] pt-[24px] pl-[24px] pb-[21px] pr-[40px]">
        <Image
          src={getImgUrl(thumbnail)}
          alt="thumbnail"
          objectFit="fill"
          height={280}
          width={400}
          loading="lazy"
        />
      </div>

      <div className="flex flex-col items-center gap-y-[24px] md:w-3/5 md:items-start">
        <h3 className="text-center font-satoshi-bold text-[16px] leading-6 line-clamp-2 md:text-left md:text-[30px] md:leading-[38px]">
          {description}
        </h3>

        <Primary
          linkCTA
          href={link}
          target="_blank"
          rel="noreferrer"
          label={'Read Coverage'}
          size={'large'}
        >
          <FaArrowRight size={20} />
        </Primary>
      </div>
    </div>
  );
}

export function RecognitionCertificate() {
  const awards = [awardOne, awardTwo, awardThree];

  return (
    <div className="flex flex-col items-center gap-y-[56px]">
      <h4 className="text-center font-satoshi-medium font-satoshi-medium text-[24px] font-medium  leading-8">
        Recognized as Leaders in Tech Education
      </h4>

      <div className="hidden grid-cols-3 items-center justify-center gap-[24px] md:grid md:gap-x-[55px]">
        {awards.map((source, index) => (
          <Image key={index} width={240} height={120} objectFit="contain" src={source} />
        ))}
      </div>

      <div className="flex flex-wrap items-center justify-center gap-[24px] md:hidden md:gap-x-[55px]">
        {awards.map((source, index) => (
          <Image key={index} width={180} height={120} objectFit="contain" src={source} />
        ))}
      </div>
    </div>
  );
}
