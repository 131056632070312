import NewSectionTitle from '@components/shared/NewSectionTitle';
import SectionTitle from '../shared/SectionTitle';
import { getImgUrl } from '@utils/functions/getImgUrl';
import Image from 'next/image';
import LazyLoad from 'react-load-lazily';

const HiringCompanies = ({
  hiringPartners,
  DownloadPlacementReport,
  isWeb3Course = false,
  newHiring = false,
  heading,
  highlightedText,
}) => {
  const chunkSize = Math.floor(hiringPartners.length / 3);

  return (
    <>
      {newHiring ? (
        <NewHiringCompanies
          hiringPartners={hiringPartners}
          heading={heading}
          highlightedText={highlightedText}
        />
      ) : (
        <div id="hiring partners" className="module overflow-hidden">
          <LazyLoad>
            <SectionTitle className="mx-12" title="Companies hiring at AlmaBetter" />
            <div className="[&>*:not(:last-child)]:h-[8rem]">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <section key={index} className="w-100% relative  overflow-hidden ">
                    <div
                      className={`flex child:flex child:gap-x-9 ${
                        index === 1
                          ? 'child:animate-infiniteScroll'
                          : 'child:animate-infiniteScrollReverse'
                      }`}
                    >
                      <section>
                        {hiringPartners
                          .slice(index * chunkSize, chunkSize * (index + 1))
                          .map(({ id, images }, index) => (
                            <div key={id} className="grid h-16 w-[180px] place-content-center">
                              <img
                                width="180px"
                                className="h-14 object-contain"
                                src={getImgUrl(images)}
                                alt="i"
                              />
                            </div>
                          ))}
                      </section>
                      <section className="ml-9">
                        {hiringPartners
                          .slice(index * chunkSize, chunkSize * (index + 1))
                          .map(({ id, images }, index) => (
                            <div key={id} className="grid h-16 w-[180px] place-content-center">
                              <img
                                width="180px"
                                className="h-14 object-contain"
                                src={getImgUrl(images)}
                                alt="i"
                              />
                            </div>
                          ))}
                      </section>
                    </div>
                  </section>
                ))}
            </div>
            {DownloadPlacementReport && !isWeb3Course && (
              <div className="mx-auto mt-8 w-fit">
                <DownloadPlacementReport disabled={isWeb3Course} />
              </div>
            )}
          </LazyLoad>
        </div>
      )}
    </>
  );
};

export default HiringCompanies;

function NewHiringCompanies({ hiringPartners = [], heading, highlightedText }) {
  const chunkSize = Math.floor(hiringPartners.length / 3);

  return (
    <div className="module flex flex-col items-center gap-y-[64px] py-[112px]" id="hiring partners">
      {heading ? (
        <NewSectionTitle text={heading} highlightedText={highlightedText} />
      ) : (
        <h2 className="font-satoshi-medium font-satoshi-medium text-[30px] font-semibold font-bold leading-9 md:font-satoshi-bold md:font-satoshi-bold md:text-[36px]  md:leading-none">
          Our Hiring{' '}
          <span className="relative -top-3 text-red underline underline-offset-8">Partners</span>
        </h2>
      )}

      <div className="flex w-full flex-col gap-y-[40px] overflow-hidden py-[4px]">
        {Array(3)
          .fill(null)
          .map((_, index) => (
            <div
              className={`grid h-full w-full grid-flow-col items-center gap-x-[40px] ${
                index === 1 ? 'animate-infiniteScroll' : 'animate-infiniteScrollReverse'
              }`}
              key={index}
            >
              {hiringPartners
                .slice(index * chunkSize, chunkSize * (index + 1))
                .map(({ id, images }, index) => (
                  <div
                    className="relative flex h-12 w-40 items-center justify-center rounded-full bg-white py-[8px] px-[16px]"
                    style={{
                      boxShadow:
                        '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                    key={index}
                  >
                    <Image src={getImgUrl(images)} height={40} width={140} objectFit="contain" />
                    {/* <img src={getImgUrl(images)} alt="i" className="h-full w-full object-contain" /> */}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}
