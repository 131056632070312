import { useDispatch } from 'globalStore/storeProvider';
import PartnersLogo from '../PartnersLogo';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Primary, SecondaryColor } from 'stories/Button.stories';

const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F9E0E2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.096 7.38967L9.93602 14.2997L8.03602 12.2697C7.68602 11.9397 7.13602 11.9197 6.73602 12.1997C6.34602 12.4897 6.23602 12.9997 6.47602 13.4097L8.72602 17.0697C8.94602 17.4097 9.32601 17.6197 9.75601 17.6197C10.166 17.6197 10.556 17.4097 10.776 17.0697C11.136 16.5997 18.006 8.40967 18.006 8.40967C18.906 7.48967 17.816 6.67967 17.096 7.37967V7.38967Z"
      fill="#F00037"
    />
  </svg>
);

const PaymentCard = ({
  planDetails,
  courseCTAs,
  degree,
  isCareerServiceFee = false,
  isWeb3Course = false,
}) => {
  const dispatch = useDispatch();
  const { buttonLink } = courseCTAs;

  const openRCBWidget = (subPlanType = null) => {
    subPlanType && sessionStorage.setItem('subPlanType', subPlanType);
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.callback });
  };
  const isArray = Array.isArray(planDetails);

  const borderColour = ['#2297EC', '#9060C0', '#C859AF'];

  const [activeTab, setActiveTab] = useState(0);
  const tabListRef = useRef(null);

  const scrollToTab = (index) => {
    setActiveTab(index);
    if (tabListRef.current) {
      tabListRef.current.children[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  return (
    <>
      {isCareerServiceFee && Array.isArray(planDetails) && (
        <p className="mb-6 text-center font-satoshi-regular text-neutral-600">
          {planDetails[0]?.Heading}
        </p>
      )}
      {isArray && planDetails?.length > 1 ? (
        <>
          <div className="block md:hidden">
            <Tab.Group defaultIndex={activeTab}>
              <Tab.List
                ref={tabListRef}
                className={'mb-4 flex items-center justify-center gap-x-4 overflow-x-scroll pb-4'}
              >
                {planDetails.map((plan, index) => (
                  <Tab
                    key={plan?.PlanName}
                    onClick={() => scrollToTab(index)}
                    className={({ selected }) =>
                      classNames(
                        'w-28 rounded-lg border py-2 font-satoshi-medium text-base font-medium  outline-none',
                        selected ? 'border-red bg-rose-100 text-red' : 'text-neutral-500'
                      )
                    }
                  >
                    <p className="flex flex-col items-center justify-center	 whitespace-nowrap font-satoshi-medium font-medium  capitalize leading-5">
                      {plan?.PlanName}
                    </p>
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                {planDetails.map((plan, index) => (
                  <Tab.Panel key={plan?.PlanName} className={'flex items-center justify-center'}>
                    <ListCard
                      key={plan?.id}
                      plan={plan}
                      buttonLink={buttonLink}
                      openWidget={openRCBWidget}
                      borderColour={borderColour[index]}
                      isCareerServiceFee={isCareerServiceFee}
                      isWeb3Course={isWeb3Course}
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
          <>
            <div className="hidden justify-center gap-x-12 md:flex">
              {planDetails?.map((plan, index) => {
                return (
                  <ListCard
                    key={plan?.id}
                    plan={plan}
                    buttonLink={buttonLink}
                    openWidget={openRCBWidget}
                    borderColour={borderColour[index]}
                    isCareerServiceFee={isCareerServiceFee}
                    isWeb3Course={isWeb3Course}
                  />
                );
              })}
            </div>
          </>
        </>
      ) : (
        <Card
          plan={isArray ? planDetails?.[0] : planDetails}
          degree={degree}
          buttonLink={buttonLink}
          openWidget={openRCBWidget}
          isCareerServiceFee={isCareerServiceFee}
        />
      )}
    </>
  );
};

export default PaymentCard;

function ListCard({
  plan,
  buttonLink,
  openWidget,
  borderColour,
  isCareerServiceFee,
  isWeb3Course = false,
}) {
  const subPlayQuery = plan?.subPlanType ? `?subPlanType=${plan?.subPlanType}` : '';
  return (
    <div
      className={`relative flex max-w-[23.5rem] flex-col gap-y-4 overflow-hidden rounded-xl border border-t-[12px] bg-white py-6 text-center drop-shadow-xl`}
      style={{ borderColor: borderColour }}
    >
      {plan?.PlanName === 'Iconic' && (
        <div className="absolute -left-8 -rotate-45" style={{ backgroundColor: borderColour }}>
          <p className="px-6 font-satoshi-regular font-satoshi-medium font-semibold text-white">
            Most Popular
          </p>
        </div>
      )}
      <h4 className="font-satoshi-regular font-satoshi-medium text-2xl font-medium  text-red">
        {plan?.PlanName}
      </h4>
      <div className="flex items-center justify-center px-1">
        <PartnersLogo data={plan?.Partners} />
      </div>
      <div
        className={`flex items-center justify-center gap-x-2 ${
          isCareerServiceFee ? 'flex-col' : ''
        }`}
      >
        <h5 className="font-satoshi-regular font-satoshi-bold text-4xl font-bold ">
          Rs. {plan?.PlanPrice?.toLocaleString()}
        </h5>
        <span className="font-satoshi-regular text-base">
          {!isCareerServiceFee ? '+ 18% GST' : '(Inclusive of taxes)'}
        </span>
      </div>
      <p className="px-4 font-satoshi-regular text-sm font-light text-neutral-600">
        *Pay in easy EMIs starting at INR {plan?.EMIprice} per month.
      </p>
      <div className="px-4 pt-4">
        {plan?.Features?.map(({ id, feature }, index) => {
          return (
            <p
              key={id}
              className={`mb-2 flex items-center justify-start gap-x-3 text-left font-satoshi-regular text-sm leading-5 text-neutral-600 ${
                index === 0 && plan?.PlanName !== 'Premium' ? 'font-satoshi-bold font-bold ' : ''
              }`}
            >
              <span>
                <CheckIcon />
              </span>
              {feature}
            </p>
          );
        })}
      </div>
      <div className="mt-auto grid grid-rows-2 items-center gap-y-6 px-8">
        <Primary
          linkCTA
          href={`${buttonLink}${subPlayQuery}`}
          target="_blank"
          data-cta="enrol"
          disabled={isWeb3Course}
          label={'Enrol Now'}
          size="large"
        />
        <SecondaryColor
          onClick={() => openWidget(plan?.subPlanType)}
          disabled={isWeb3Course}
          label={'Talk to our Admission Team'}
          size="large"
        />
      </div>
    </div>
  );
}

function Card({ plan, buttonLink, openWidget, degree, isCareerServiceFee }) {
  const subPlayQuery = plan?.subPlanType ? `&subPlanType=${plan?.subPlanType}` : '';
  return (
    <>
      {plan?.description && <p className="mb-6 text-center text-neutral-600">{plan.description}</p>}
      <div className="flex justify-center">
        <PartnersLogo data={plan?.Partners} />
      </div>
      <div className="flex flex-wrap items-center justify-between gap-y-6 rounded-lg p-4 text-center shadow-[rgba(60,64,67,0.15)_0_2px_6px_2px] md:flex-nowrap ">
        <div
          className=" flex w-[24rem] flex-col gap-y-1 rounded-lg px-6 py-10"
          style={{ background: '#FBEAEA' }}
        >
          <div
            className={`flex items-center justify-center gap-x-2 pb-2 ${
              isCareerServiceFee ? 'flex-col' : ''
            }`}
          >
            <h4 className="font-satoshi-bold text-4xl font-bold ">
              Rs. {(plan?.PlanPrice || plan?.finalPrice)?.toLocaleString()}
            </h4>
            <span className="text-base font-normal">
              {!isCareerServiceFee ? '+ 18% GST' : '(Inclusive of taxes)'}
            </span>
          </div>

          <p className="text-sm font-light text-neutral-600">
            {plan?.EMIprice || plan?.EMI
              ? `Pay in easy EMI of INR ${(plan?.EMIprice || Number(plan?.EMI))?.toLocaleString()} `
              : ''}
            per month.
          </p>

          <div className="mt-4 grid items-center gap-y-4">
            <Primary
              linkCTA
              href={`${buttonLink}${subPlayQuery}`}
              target="_blank"
              data-cta="enrol"
              label={degree ? 'Start Your Application' : 'Enrol Now'}
              size="large"
            />
            <SecondaryColor
              onClick={() => openWidget(plan?.subPlanType)}
              label={'Talk to our Admission Team'}
              size="large"
            />
          </div>
        </div>
        <div className="md:w-7/12 md:px-4">
          <div className="grid grid-cols-6 items-center gap-y-4 md:grid-cols-12">
            {(plan?.features || plan?.Features)?.map(({ id, feature }, index) => {
              return (
                <>
                  <span className="center col-span-1 grid h-7 w-7 rounded-full bg-[#F9E0E2] text-red">
                    {index + 1}
                  </span>
                  <p
                    key={id}
                    className="col-span-5 grid text-left leading-5 text-neutral-600 md:col-span-11"
                  >
                    {feature}
                  </p>
                </>
              );
            })}
          </div>
          {degree && plan?.description && (
            <div
              className="mt-6 w-fit rounded-md bg-green-cyan
p-3 py-1 text-blue-midnight "
            >
              Get upto 50% Scholarship
            </div>
          )}
        </div>
      </div>
    </>
  );
}
