import useMediaQuery from '@utils/hooks/useMediaQuery';
import { useContext, useState } from 'react';
import { Tabs, TabsContainer } from './Tabs';
import { Primary } from 'stories/Button.stories';

import ListLayout from '../curriculum/ListLayout';
import TopicCard from '../curriculum/TopicCard';

const ModuleTabs = ({ items, courseCTAs, isWeb3Course = false }) => {
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const isMobile = useMediaQuery('(max-width: 767.98px)');
  const { exploreCurriculumLink } = courseCTAs;

  const handleItemClick = (item) => {
    setSelectedItem((prevState) => (isMobile ? (prevState?.id === item?.id ? null : item) : item));
  };

  return (
    <>
      <TabsContainer>
        <Tabs>
          {items.map((item, index) => {
            const { id, attributes } = item;
            const active = selectedItem?.id === id;
            return (
              <li className="mb-5" key={id}>
                <ListLayout
                  iteration={Number(index + 1).toLocaleString('en-IN', { minimumIntegerDigits: 2 })}
                  title={attributes.title}
                  selected={active}
                  onClick={() => handleItemClick(item)}
                  description={attributes.description}
                />
                {isMobile && active && (
                  <div className="mt-5 block md:hidden">
                    <TopicCard
                      duration={attributes.duration}
                      topics={selectedItem?.attributes?.learningTopics}
                      layoutStyle={'!h-fit'}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </Tabs>
        <div className="hidden md:col-span-1 md:block">
          {!isMobile && (
            <TopicCard
              duration={selectedItem?.attributes?.duration}
              topics={selectedItem?.attributes?.learningTopics}
              layoutStyle={'!h-fit'}
            />
          )}
        </div>
      </TabsContainer>
      {exploreCurriculumLink && (
        <div className="flex w-full items-center justify-center pb-[42px]">
          <Primary
            linkCTA
            href={exploreCurriculumLink}
            target="_blank"
            data-cta="curriculum"
            label={'Explore Curriculum'}
            disabled={isWeb3Course}
            size="large"
          />
        </div>
      )}
    </>
  );
};

export default ModuleTabs;
