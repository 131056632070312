import microsoft from '@assets/companies/microsoft.svg';
import netflix from '@assets/companies/netflix.svg';
import linkedin from '@assets/companies/linkedin.svg';
import google from '@assets/companies/google.svg';
import uber from '@assets/companies/uber.svg';
import Image from 'next/image';
import { getImgUrl } from '@utils/functions/getImgUrl';
import SliderComponent from '@components/shared/CustomSlider';

const EducatorsAndMentors = ({ educatorsList = [] }) => {
  const companies = [google, linkedin, uber, microsoft, netflix];

  const educatorsListByRank = educatorsList.sort(
    (a, b) => (a.attributes.rank ?? Infinity) - (b.attributes.rank ?? Infinity)
  );

  return (
    <div
      id="educators"
      className="module flex flex-col items-center gap-y-[32px] bg-[#F9FAFB] px-[16px] pt-[44px] pb-[60px] md:gap-y-[48px] md:px-[124px] md:pt-[112px] md:pb-[80px]"
    >
      <div className="flex flex-col gap-y-[16px]">
        <h2 className="text-center font-satoshi-bold text-[30px] capitalize leading-9 md:text-[36px] md:leading-none">
          Top 1%{' '}
          <span className="relative -top-2 text-red underline underline-offset-8">Educators</span>
        </h2>
        <p className="text-center font-satoshi-regular text-[14px] capitalize leading-[20px] text-gray-400 md:text-[20px] md:leading-[30px]">
          Get taught by educators from top educational institutions and companies
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-[16px] pb-[48px] md:gap-x-[64px]">
        {companies?.map((imageSrc, index) => (
          // large screen
          <Image
            key={index}
            height={34}
            width={108}
            objectFit="contain"
            src={imageSrc}
            alt="company"
            loading="lazy"
          />
        ))}
      </div>
      {educatorsListByRank.length > 0 && (
        <div className="w-full">
          <SliderComponent
            swiperOptions={{
              breakpoints: {
                320: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                  slidesPerGroup: 1,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 32,
                  slidesPerGroup: 4,
                },
              },
            }}
            buttonStyle={{
              size: 24,
              buttonClass: 'col-span-6 rounded-full',
              leftButton: 'place-self-end',
              rightButton: 'place-self-start',
            }}
          >
            {educatorsListByRank.map(({ id, attributes }) => {
              return <EducatorsLogoCard {...attributes} key={id} />;
            })}
          </SliderComponent>
        </div>
      )}
    </div>
  );
};

export default EducatorsAndMentors;

function EducatorsLogoCard({ DisplayPicture, Name, Designation, CompanyImage }) {
  return (
    <div className="flex flex-col items-center gap-y-[20px]">
      <Image
        src={getImgUrl(DisplayPicture)}
        width={173}
        height={173}
        objectFit="contain"
        className="rounded-lg bg-white"
        loading="lazy"
      />
      <div className="flex flex-col items-center gap-y-[12px]">
        <div className="flex flex-col items-center gap-y-[8px]">
          <h3 className="text-center font-satoshi-bold text-[16px] leading-6">{Name}</h3>
          <p className="text-center font-satoshi-medium text-[14px] leading-[20px] text-gray-400">
            {Designation}
          </p>
        </div>
        <Image
          src={getImgUrl(CompanyImage)}
          width={120}
          height={40}
          objectFit="contain"
          loading="lazy"
        />
      </div>
    </div>
  );
}
