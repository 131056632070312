import { AiOutlineClose } from 'react-icons/ai';

const CertificateZoomModal = ({ open, close, content }) => {
  return (
    <>
      {open && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={close}
        >
          <div className="w-full lg:w-3/5" onClick={(e) => e.stopPropagation()}>
            <div className={`relative animate-fade-in`}>
              {content}
              <button
                className="absolute top-2 right-2 aspect-square w-5 lg:top-0 lg:-right-10 lg:w-8"
                onClick={close}
              >
                <AiOutlineClose className="h-4 w-4 lg:h-8 lg:w-8" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CertificateZoomModal;
