import { useRouter } from 'next/router';
import SectionTitle from '../shared/SectionTitle';
import PaymentCard from './PaymentCard';
import { Tab } from '@headlessui/react';
import DarkLayout from '@components/shared/DarkLayout';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Secondary, HierarchyPrimary } from 'stories/Button.stories';
import { useDispatch } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';
import Modal from '@components/shared/Modal';
import { IoCloseOutline } from 'react-icons/io5';
import cn from 'classnames';
import NewSectionTitle from '@components/shared/NewSectionTitle';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PaymentPlans = ({
  coursePlans = {},
  nextCohort,
  courseCTAs,
  degree,
  DownloadBrouchure,
  UGCourse,
  courseData,
  isWeb3Course,
}) => {
  const coursePlanKeys = Object.keys(coursePlans);
  const router = useRouter();

  return (
    <>
      {degree ? (
        <PaymentPlanForDegree coursePlan={coursePlans?.tuition_fee?.[0]} courseCTAs={courseCTAs} />
      ) : (
        <section
          id={'Program Fee'}
          className="module flex flex-col gap-y-[32px] px-[16px] py-[40px] md:gap-y-[60px] md:px-[124px] md:py-[80px]"
        >
          <NewSectionTitle text={'Course'} highlightedText={'Fee'} />

          {coursePlanKeys.length > 1 ? (
            <Tab.Group>
              <Tab.List className="mb-4 flex items-center justify-center gap-8 rounded-lg p-1">
                {coursePlanKeys.map((title, index) => (
                  <Tab
                    key={title}
                    className={({ selected }) =>
                      classNames(
                        'py-2 font-satoshi-medium text-base font-medium  outline-none lg:text-xl',
                        selected
                          ? 'border-b-4 border-red text-red'
                          : 'border-transparent text-black'
                      )
                    }
                  >
                    <h3 className="px-4 font-satoshi-regular font-satoshi-medium font-semibold capitalize leading-5 md:px-6">
                      {title?.split('_').join(' ')}
                    </h3>
                  </Tab>
                ))}
              </Tab.List>

              <Tab.Panels>
                {coursePlanKeys.map((title) => (
                  <Tab.Panel key={title}>
                    <PaymentCard
                      courseCTAs={courseCTAs}
                      planDetails={coursePlans[title]}
                      courseData={courseData}
                      nextCohort={nextCohort}
                      degree={degree}
                      DownloadBrouchure={DownloadBrouchure}
                      UGCourse={UGCourse}
                      isCareerServiceFee={title === 'career_services_fee'}
                      isWeb3Course={isWeb3Course}
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          ) : (
            <PaymentCard
              courseCTAs={courseCTAs}
              planDetails={Object.values(coursePlans)[0]}
              courseData={courseData}
              nextCohort={nextCohort}
              degree={degree}
              DownloadBrouchure={DownloadBrouchure}
              UGCourse={UGCourse}
            />
          )}
        </section>
      )}
    </>
  );
};

export default PaymentPlans;

function PaymentPlanForDegree({ coursePlan = {}, courseCTAs = {} }) {
  const dispatch = useDispatch();
  if (!Object.keys(coursePlan).length) return <></>;

  const openRCBWidget = (subPlanType = null) => {
    subPlanType && sessionStorage.setItem('subPlanType', subPlanType);
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.callback });
  };

  const paymentPlan = [
    {
      title: 'EMI Options',
      description: 'Our Partners offer you Financing options for as low as',
      highlight: '0% interest',
      price: coursePlan.EMIprice,
      note: 'Pay in easy monthly installments',
      secondaryCTA: 'View EMI Plans',
      isEMI: true,
    },
    {
      title: 'Pay in Full',
      description: 'Use Internet Banking, Digital Wallets, Debit/Card for one-time Payment',
      highlight: '',
      price: coursePlan.PlanPrice,
      note: '+18% GST',
      secondaryCTA: 'Talk to our admission team',
      isEMI: false,
    },
  ];

  return (
    <DarkLayout
      customStyle="module !flex flex-col items-center gap-y-[32px] md:gap-y-[72px] px-[16px] pt-[40px] pb-[68px] md:px-[124px] md:pt-[96px] md:pb-[125px]"
      gridMode
      id={'Program Fee'}
    >
      <h2 className="z-1 relative flex items-center gap-x-[10px] text-center font-satoshi-bold text-[30px] leading-9 text-white md:text-[36px] md:leading-tight">
        Course{' '}
        <span className="relative -top-2 text-red underline underline-offset-8">Tuition Fee</span>
      </h2>

      <div className="z-1 relative grid w-full gap-y-[48px] md:grid-cols-12">
        <div className="flex flex-col items-center gap-y-[32px] md:col-span-4 md:items-start">
          <h3 className="text-center font-satoshi-bold text-[24px] leading-8 text-white md:text-left md:text-[36px] md:leading-tight">
            What you get
          </h3>
          <div className="flex flex-col items-start gap-y-[16px]">
            {coursePlan.Features.map(({ id, feature }) => (
              <div key={id} className="grid grid-cols-12 items-start gap-x-[12px]">
                <IoIosCheckmarkCircleOutline className="mt-1 text-red" size={24} />

                <h4 className="col-span-11 font-satoshi-regular text-[16px] leading-6 text-white md:text-[14px] md:leading-6">
                  {feature}
                </h4>
              </div>
            ))}
          </div>
        </div>
        <div className="order-first grid gap-y-[24px] gap-x-[27px] md:order-none md:col-span-8 md:col-start-6 md:grid-cols-2">
          {paymentPlan.map((item, index) => (
            <PaymentCardDark
              {...item}
              key={index}
              CTALink={courseCTAs?.buttonLink}
              openWidget={openRCBWidget}
            />
          ))}
        </div>
      </div>
    </DarkLayout>
  );
}

function PaymentCardDark({
  title,
  description,
  highlight,
  price = 0,
  note,
  isEMI = false,
  secondaryCTA,
  CTALink = '',
  openWidget,
}) {
  return (
    <div className="gradient-border-dark-145 flex flex-col items-center gap-y-[40px] rounded-xl p-[24px]">
      <div className="flex flex-col items-center gap-y-[4px]">
        <h3 className="font-satoshi-bold text-[20px] leading-[30px] text-white">{title}</h3>
        <p className="text-center font-satoshi-regular text-[16px] leading-6 text-gray-subtitle">
          {description}{' '}
          {highlight && (
            <span className="font-satoshi-bold font-bold  text-white">{highlight}</span>
          )}
        </p>
      </div>

      <div className="flex flex-col items-center gap-y-[8px]">
        <h4 className="font-satoshi-bold text-[36px] leading-none text-white">
          Rs. {price.toLocaleString()}
          {isEMI ? '/mo' : ''}
        </h4>
        <p className="font-satoshi-medium text-[14px] leading-[20px] text-[#A3A3A3]">{note}</p>
      </div>

      <div className="mt-auto grid w-full gap-y-[16px]">
        <Secondary label={'Enroll Now'} linkCTA href={CTALink} />

        {isEMI ? (
          <Modal
            open={<HierarchyPrimary label={secondaryCTA} customStyle={'justify-self-center'} />}
          >
            <EMIPlans />
          </Modal>
        ) : (
          <HierarchyPrimary
            label={secondaryCTA}
            customStyle={'justify-self-center'}
            onClick={!isEMI && openWidget}
          />
        )}
      </div>
    </div>
  );
}

function EMIPlans({ closeModal }) {
  const duration = [12, 18, 20, 24, 36];

  const emiDetails = [
    {
      planName: 'No-Cost EMI',
      totalAmount: '2,80,000 + GST',
      downPayment: '11,667',
      monthlyPayment: '11,181',
    },
    {
      planName: 'Standard EMI',
      totalAmount: '2,80,000 + GST',
      downPayment: 'N/A',
      monthlyPayment: '8,633',
    },
  ];

  return (
    <div className="relative flex flex-col gap-y-[37px] p-[32px]">
      <div className="absolute top-4 right-4">
        <IoCloseOutline size={24} className="cursor-pointer" onClick={closeModal} />
      </div>

      <div className=" flex flex-col items-center gap-y-[12px]">
        <h5 className="font-satoshi-bold text-[30px] leading-[38px]">EMI options</h5>
        <p className="text-center font-satoshi-medium text-[14px] leading-5 text-gray-400">
          Explore No-cost EMI & Standard EMI options from our NBFC Partners such as Bajaj,
          Liquiloans, Varthana & others
        </p>
      </div>

      <div
        className="md:no-scrollbar w-full overflow-x-scroll rounded-lg pb-[4px]"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        }}
      >
        <table className="w-full">
          <thead>
            <tr className="child:text-medium border-b border-gray-2 bg-[#fcfcfd] font-satoshi-medium text-[#475467] child:min-w-[200px] child:px-[24px] child:py-[12px] child:text-left child:text-[12px] child:leading-[18px]">
              <th></th>
              <th>Total Amount</th>
              <th>Min. Down Payment</th>
              <th>Duration (months)</th>
              <th>Monthly Payments</th>
            </tr>
          </thead>
          <tbody>
            {emiDetails.map(({ planName, totalAmount, downPayment, monthlyPayment }, index) => (
              <tr
                className={cn(
                  'border-gray-2 font-satoshi-medium text-[#475467] child:px-[24px] child:py-[12px] child:text-left child:text-[14px] child:leading-[20px]',
                  {
                    'border-2': index < emiDetails.length - 1,
                    'border-0': index === emiDetails.length - 1,
                  }
                )}
                key={index}
              >
                <td className="font-satoshi-bold font-satoshi-bold font-bold ">{planName}</td>
                <td>Rs. {totalAmount}</td>
                <td>Rs. {downPayment}</td>
                <td>{duration.join(', ')}</td>
                <td>Rs. {monthlyPayment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
