import { Tab } from '@headlessui/react';
import React, { Fragment } from 'react';
import cn from 'classnames';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import Image from 'next/image';
import DarkLayout from '@components/shared/DarkLayout';
import { Primary, Secondary } from 'stories/Button.stories';
import { FaArrowRight } from 'react-icons/fa';
import { scrollToElement } from '@utils/functions/throttle';
import python from '@assets/skills/python.png';
import pandas from '@assets/skills/pandas.png';
import numpy from '@assets/skills/numpy.png';
import pytorch from '@assets/skills/pytorch.png';
import matplotlib from '@assets/skills/matplotlib.png';
import SQL from '@assets/skills/SQL.png';
import docker from '@assets/skills/docker.png';
import git from '@assets/skills/git.png';
import AWS from '@assets/skills/aws-1.png';
import tableau from '@assets/skills/tableau.png';
import tensorflow from '@assets/skills/tensor-flow.png';
import GPT from '@assets/skills/gpt.png';

import html from '@assets/skills/html.png';
import css from '@assets/skills/css.png';
import javascript from '@assets/skills/javascript.png';
import react from '@assets/skills/react.png';
import next from '@assets/skills/next.png';
import tailwind from '@assets/skills/tailwind.png';
import node from '@assets/skills/node-js.png';
import mongodb from '@assets/skills/mongodb.png';
import github from '@assets/skills/github.png';
import nginx from '@assets/skills/nginx.png';
import jenkins from '@assets/skills/jenkins.png';

import powerBI from '@assets/skills/power-bi.png';
import sciKit from '@assets/skills/scikit.svg';
import xgBoost from '@assets/skills/xgboost.svg';
import sciPy from '@assets/skills/scipy.svg';
import NLTK from '@assets/skills/NLTK.png';

import claude from '@assets/skills/claude.svg';
import langChain from '@assets/skills/langchain.svg';
import huggingFAce from '@assets/skills/hugging-face.svg';
import midwayJourney from '@assets/skills/midway-journey.svg';
import gemini from '@assets/skills/gemini.svg';
import dallE from '@assets/skills/dall-e.svg';
import runwayMl from '@assets/skills/runway-ml.svg';
import gradio from '@assets/skills/gradio.svg';

const COURSE_TYPE_ROLES = {
  'DATA SCIENCE': {
    skills: [
      {
        logo: python,
        skill: 'Python',
      },
      {
        logo: pandas,
        skill: 'Pandas',
      },
      {
        logo: numpy,
        skill: 'NumPy',
      },
      {
        logo: matplotlib,
        skill: 'Matplotlib',
      },
      {
        logo: SQL,
        skill: 'SQL',
      },
      {
        logo: tableau,
        skill: 'Tableau',
      },
      {
        logo: powerBI,
        skill: 'PowerBI',
      },
      {
        logo: git,
        skill: 'GIT',
      },
      {
        logo: github,
        skill: 'GitHub',
      },
      {
        logo: sciKit,
        skill: 'Scikit-Learn',
      },
      {
        logo: xgBoost,
        skill: 'XGBoost',
      },
      {
        logo: NLTK,
        skill: 'NLTK',
      },
      {
        logo: sciPy,
        skill: 'SciPy',
      },
      {
        logo: pytorch,
        skill: 'PyTorch',
      },
      {
        logo: tensorflow,
        skill: 'TensorFlow',
      },
      {
        logo: GPT,
        skill: 'Chat GPT',
      },
      {
        logo: claude,
        skill: 'Claude',
      },

      {
        logo: langChain,
        skill: 'LangChain',
      },
      {
        logo: huggingFAce,
        skill: 'Hugging Face',
      },
      {
        logo: midwayJourney,
        skill: 'MidJourney',
      },
      {
        logo: gemini,
        skill: 'Gemini',
      },
      {
        logo: dallE,
        skill: 'Dall-E',
      },
      {
        logo: runwayMl,
        skill: 'Runway ML',
      },
      {
        logo: gradio,
        skill: 'Gradio',
      },
    ],
    roles: [
      'Data Analyst',
      'Data Scientist',
      'Data Engineer',
      'ML Engineer',
      'AI Engineer',
      'Business Analyst',
      'LLM Engineer',
    ],
  },
  'WEB DEVELOPMENT': {
    skills: [
      {
        logo: html,
        skill: 'HTML5',
      },
      {
        logo: css,
        skill: 'CSS3',
      },
      {
        logo: javascript,
        skill: 'JavaScript',
      },
      {
        logo: react,
        skill: 'React JS',
      },
      {
        logo: next,
        skill: 'Next JS',
      },
      {
        logo: tailwind,
        skill: 'Tailwind CSS',
      },
      {
        logo: node,
        skill: 'NodeJs',
      },
      {
        logo: SQL,
        skill: 'SQL',
      },
      {
        logo: mongodb,
        skill: 'MongoDB',
      },
      {
        logo: git,
        skill: 'Git',
      },
      {
        logo: github,
        skill: 'GitHub',
      },
      {
        logo: nginx,
        skill: 'Nginx',
      },
      {
        logo: jenkins,
        skill: 'Jenkins',
      },
      {
        logo: docker,
        skill: 'Docker',
      },
      {
        logo: AWS,
        skill: 'AWS',
      },
      {
        logo: GPT,
        skill: 'ChatGPT',
      },
    ],
    roles: [
      'Frontend Developer',
      'Backend Developer',
      'Full Stack Developer',
      'Web Developer',
      'UI Developer',
      'Android Developer',
      'IOS Developer',
    ],
  },
};

function CandidateRoles() {
  return (
    <DarkLayout customStyle="!flex flex-col items-center !gap-y-[64px] px-[16px] py-[60px] md:py-[120px] md:px-[124px]">
      <NewSectionTitle
        headingStyle={'text-white'}
        text={'We train the right candidates for the'}
        highlightedText={'Right roles'}
        underlineStyle={'!underline-offset-[12px]'}
      />

      <Tab.Group>
        <Tab.List className={'flex w-full items-center justify-center gap-x-[16px]'}>
          {Object.keys(COURSE_TYPE_ROLES).map((key, index) => (
            <Tab key={index} as={Fragment}>
              {({ selected }) => (
                <button
                  className={cn(
                    'relative z-10 border-b-2 px-[8px] py-[8px] !font-satoshi-medium text-[18px] leading-7 tracking-[1.25px] focus:outline-none md:px-[16px]',
                    { 'border-red text-red': selected, 'border-transparent text-white': !selected }
                  )}
                  style={{ fontWeight: 600 }}
                >
                  {key}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className={'w-full'}>
          {Object.entries(COURSE_TYPE_ROLES).map(([courseName, { roles, skills }], roleIndx) => (
            <Tab.Panel
              key={roleIndx}
              className={'relative z-10 flex flex-col items-center gap-y-[48px]'}
            >
              <div className="flex flex-wrap items-center justify-center gap-[16px]">
                {roles.map((role, index) => (
                  <Secondary key={index} label={role} selected />
                ))}
              </div>

              <div className="w-11/12">
                <SkillSet skillsSet={skills} isWebCourse={courseName === 'WEB DEVELOPMENT'} />
              </div>

              <Primary
                label={'Start Hiring'}
                size={'large'}
                customStyle="flex items-center gap-x-[8px] mt-10"
                onClick={() => scrollToElement('hireForm')}
              >
                <FaArrowRight size={20} className="mt-1" />
              </Primary>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </DarkLayout>
  );
}

export default CandidateRoles;

export function SkillSet({ skillsSet = [], isWebCourse }) {
  return (
    <div
      className={cn('grid grid-cols-4 gap-x-[32px] gap-y-[48px] md:grid-cols-8', {
        // 'grid-cols-4 md:grid-cols-10': !isWebCourse,
        // 'grid-cols-4 md:grid-cols-8': isWebCourse,
      })}
    >
      {skillsSet.map(({ skill, logo }, index) => (
        <div className="flex flex-col items-center gap-y-[8px]" key={index}>
          <span className="bytes-card-bg flex h-[76px] w-[76px] items-center justify-center rounded-2xl">
            <Image src={logo} width={32} height={32} objectFit="contain" />
          </span>
          <p className="text-center font-satoshi-medium text-[14px] leading-5 text-white">
            {skill}
          </p>
        </div>
      ))}
    </div>
  );
}
