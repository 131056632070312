import DegreeJourney from './DegreeJourney';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import { ExtractTitleAndSubTitle } from '@utils/utils';

const LearningEssentials = ({
  courseData,
  courseCTAs,
  degree,
  className,
  AlmaX = false,
  UGCourse,
  heading = '',
  description = '',
  ...props
}) => {
  const { title, subTitle } = ExtractTitleAndSubTitle(
    AlmaX ? courseData?.AlmaX?.title : heading,
    3
  );

  return (
    <div
      {...props}
      className={`${className} relative z-10 flex flex-col gap-y-[72px] overflow-hidden px-3 pt-14 lg:px-16`}
    >
      <div className="flex flex-col gap-y-[16px]">
        <NewSectionTitle
          text={title || `Curriculum that makes`}
          highlightedText={subTitle || 'you job ready'}
          underlineStyle={'!underline-offset-[12px] !capitalize'}
        />

        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-[#A3A3A3] md:text-[18px] md:leading-7">
          {AlmaX
            ? courseData?.AlmaX?.description
            : description ||
              'We have designed our curriculum and learning pedagogy based on what top tech companies expect when you join them after you graduate. The curriculum focuses on learning by doing including solving real world problems and working on real industry level projects.'}
        </p>
      </div>
      {AlmaX && (
        <svg
          viewBox="0 0 1470 1340"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 right-0 -z-10"
        >
          <path
            opacity="0.4"
            d="M753 0H540L886 300.638L0 1151.5V1339.5L998.5 398.623L1248.5 621H1469.5L1110 300.638L1451.5 0H1223.5L999.5 206.788L753 0Z"
            fill="url(#paint0_linear_7731_1050)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_7731_1050"
              x1="802.25"
              y1="0"
              x2="802.25"
              y2="1023.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="0.859374" stopColor="#292929" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <DegreeJourney
        courseCTAs={courseCTAs}
        courseData={courseData}
        degree={degree}
        UGCourse={UGCourse}
        AlmaX={AlmaX}
        isWeb3Course={props?.isWeb3Course || false}
      />
    </div>
  );
};

export default LearningEssentials;
