import Image from 'next/image';
import { sectionScroll } from '../../shared/scetionScroll';
const CTA = ({ buttonText, showIcons = false, buttonLink = '/courses', queryString }) => {
  return (
    <div
      className="my-20 flex flex-col items-center justify-center rounded-md p-8 lg:flex-row"
      style={{
        background: 'linear-gradient(124.35deg, #FAD3D3 29.7%, #FEEDED 74.19%)',
      }}
    >
      <div className="lg:mr-8 lg:w-[40%]">
        <Image
          src="/assets/images/Vector.png"
          alt=""
          className="mb-3"
          width={20}
          height={20}
          loading="lazy"
        />
        <div className="text-body !font-satoshi-medium">
          AlmaBetter’s curriculum is the best curriculum available online. AlmaBetter’s program is
          engaging, comprehensive, and student-centered. If you’re serious about accelerating your
          tech career, you cannot ask for a better platform than AlmaBetter.
        </div>
        <div className="mt-4 flex flex-row items-start text-sm">
          <Image
            src="/assets/images/avatar.png"
            alt=""
            height={53}
            width={53}
            loading="lazy"
            className="h-[53px] w-[53px]"
          />
          <div className="flex flex-col items-start px-4">
            <h6 className="font-satoshi-bold text-lg font-bold  ">
              <span>Kamya Malhotra</span>
            </h6>
            <div className="font-satoshi-bold text-sm font-bold  leading-[10px] text-red">
              <span>Statistical Analyst</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 w-full lg:mt-0 lg:w-[45%]">
        <div className="flex flex-col items-start justify-between rounded-md bg-white p-8 ">
          <h2 className="text-center !font-satoshi-bold">
            Fast forward your career in tech with AlmaBetter
          </h2>
          {buttonText &&
            (buttonLink !== '#featuredCourses' ? (
              <a
                href={queryString ? `${buttonLink}/?${queryString}` : buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full"
              >
                <button className="text-md mt-8 w-full rounded-lg border border-red bg-red px-8 py-3 font-satoshi-medium font-medium  uppercase text-white md:text-lg">
                  {buttonText}
                </button>
              </a>
            ) : (
              <button
                className="text-md mt-8 w-full rounded-lg border border-red bg-red px-8 py-3 font-satoshi-medium font-satoshi-medium font-medium  uppercase text-white md:text-lg"
                onClick={(e) => sectionScroll({ e, ref: buttonLink })}
              >
                {buttonText}
              </button>
            ))}
        </div>
        {showIcons && (
          <div className="mt-2 flex flex-col">
            <p className="text-base">Also available on</p>
            <div className="inline-flex gap-4">
              <a
                href="https://play.google.com/store/apps/details?id=uk.co.disciplemedia.almabettercommu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/icons/googlePlay.png"
                  alt=""
                  height={49}
                  width={160}
                  loading="lazy"
                  className="h-[49px] w-[160px]"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/almabetter/id1586671858"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/icons/appStore.png"
                  alt=""
                  height={49}
                  width={160}
                  loading="lazy"
                  className="h-[49px] w-[160px]"
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CTA;
