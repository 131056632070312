const { JOB_BOARD_COMPILER_BASE_URL } = require('@lib/api');
const { default: axios } = require('axios');

async function fetchProgramCohortData(courseType = '', planType = '') {
  //

  const URL = `${JOB_BOARD_COMPILER_BASE_URL}/api/v2/subscription-plan/upcoming-cohort?courseType=${courseType}&planType=${planType}`;

  return (await axios.get(URL)).data;
}

export default fetchProgramCohortData;
