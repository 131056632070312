import { HiOutlineCalendar } from 'react-icons/hi2';
import moment from 'moment';
import cn from 'classnames';

const NextCohort = ({ data = {}, dateOnly, noBackground, degree, showSlots = true }) => {
  if (!Object.keys(data).length) return null;

  const { startDate } = data;

  return (
    <div className="flex items-center gap-x-2 capitalize child:text-red">
      {dateOnly ? (
        <>
          <HiOutlineCalendar size={25} />
          <p className="font-satoshi-bold text-base font-bold ">
            next cohort - {moment(startDate).format('D MMM YYYY')}
          </p>
        </>
      ) : (
        <div
          className={cn('flex items-center justify-center gap-2 rounded-lg p-2 px-4', {
            'bg-linen': !noBackground,
          })}
        >
          <img
            src="/assets/events/calender.svg"
            className="aspect-square w-[18px] lg:w-6"
            alt="calender"
          />
          <p className="text-[0.80rem] leading-5 text-red md:w-full lg:text-[0.85rem] ">
            Next cohort starting on {moment(startDate).format('Do MMMM')}
            {/* {showSlots &&
              (degree
                ? ' Limited seats left'
                : availableSeats && `. Only ${availableSeats} Slots left`)} */}
          </p>
        </div>
      )}
    </div>
  );
};

export default NextCohort;
