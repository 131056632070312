import { DateTimeFormatter } from '@utils/utils';
import React from 'react';
import { CustomBadge } from 'stories/Badge.stories';
import { Primary } from 'stories/Button.stories';

function ProgramCohort({ cohortData = null, buttonLink = '' }) {
  if (!cohortData) return <></>;

  const {
    startDate,
    regularClassSchedule = null,
    selfPacedClassSchedule = null,
  } = cohortData || {};

  return (
    <div className="flex flex-col gap-y-[64px] py-[32px] px-[16px] md:py-[80px] md:px-[124px]">
      <div className="flex flex-col items-center gap-y-[12px]">
        <h2 className="text-center font-satoshi-bold text-[36px] leading-none">
          Program{' '}
          <span className="relative -top-2 text-red underline underline-offset-8">Cohorts</span>
        </h2>
        <p className="font-satoshi-medium text-[14px] leading-[20px] text-gray-400">
          Details on the upcoming Cohorts
        </p>
      </div>
      <div className="flex flex-col gap-y-[29px]">
        <div className="flex items-center gap-x-[16px]">
          <h3 className="font-satoshi-bold text-[24px] leading-8">Next Cohort</h3>
          <CustomBadge
            label={'LIMITED SEATS LEFT!'}
            size={'small'}
            backgroundColor={'#ECFDF3'}
            borderColor={'#ABEFC6'}
            labelColor={'#067647'}
          />
        </div>

        <div className="md:no-scrollbar w-full overflow-x-scroll">
          <table className="w-full">
            <thead className="program-cohort-table">
              <tr className="border-b border-gray-2 bg-[#F2F4F7]">
                <th></th>
                <th>Date</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="program-cohort-table">
              <tr className="border-b border-gray-2 font-satoshi-medium">
                <td className="font-satoshi-bold font-satoshi-bold font-bold ">
                  Program Induction
                </td>
                <td>{DateTimeFormatter(startDate)}</td>
                <td>{DateTimeFormatter(startDate, 'hh:mm A')}</td>
                <td></td>
              </tr>
              {regularClassSchedule && (
                <tr className="font-satoshi-medium">
                  <td className="font-satoshi-bold font-satoshi-bold font-bold ">
                    Regular Classes
                  </td>
                  <td>
                    {DateTimeFormatter(regularClassSchedule.startDate)} -{' '}
                    {DateTimeFormatter(regularClassSchedule.endDate)}
                  </td>
                  <td>
                    {DateTimeFormatter(
                      `${regularClassSchedule.startDate}T${regularClassSchedule.startTime}`,
                      'hh:mm A'
                    )}{' '}
                    -{' '}
                    {DateTimeFormatter(
                      `${regularClassSchedule.endDate}T${regularClassSchedule.endTime}`,
                      'hh:mm A'
                    )}
                  </td>
                  <td>
                    <Primary label={'Enroll Now'} linkCTA href={buttonLink} />
                  </td>
                </tr>
              )}
              {selfPacedClassSchedule && (
                <tr className="font-satoshi-medium">
                  <td className="font-satoshi-bold font-satoshi-bold font-bold ">
                    Self-paced Classes
                  </td>
                  <td>
                    {DateTimeFormatter(selfPacedClassSchedule.startDate)} -
                    {DateTimeFormatter(selfPacedClassSchedule.endDate)}
                  </td>
                  <td>Your Time</td>
                  <td>
                    <Primary label={'Enroll Now'} linkCTA href={buttonLink} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProgramCohort;
