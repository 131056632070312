import LearningJourneyHeader from './LearningJourneyHeader';
import cn from 'classnames';

const LearningJourneyContainer = ({
  title,
  duration,
  description,
  children,
  step,
  end,
  hideJourney = false,
}) => (
  <div className="grid h-full grid-cols-auto-1fr gap-x-2 md:min-h-[460px] md:gap-x-3">
    {!hideJourney && (
      <div className="relative col-auto flex w-8 flex-col items-center md:w-12">
        <div className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-red text-center text-white md:h-12 md:w-12">
          <p className="font-satoshi-bold text-[24px] leading-8">{step}</p>
        </div>

        <div className="primary-timeline h-[98%] w-0.5" />

        {end && <div className="h-4 w-4 rounded-full bg-red" />}
      </div>
    )}
    <div
      className={cn('flex flex-col gap-y-[16px]', {
        'col-span-1': end,
        'col-span-1': !end,
      })}
    >
      {title && <LearningJourneyHeader title={title} duration={duration} />}
      {description && <p className="pb-[16px] leading-5">{description}</p>}
      {children}
    </div>
  </div>
);

export default LearningJourneyContainer;
