import axios from 'axios';
import React, { useState } from 'react';
import moment from 'moment/moment';
import { getStrapiURL } from '../../../lib/api';
import Masonry from 'react-masonry-css';
import { customCourseTypeComparator } from '@utils/functions/throttle';
import { getImgUrl } from '@utils/functions/getImgUrl';

const apiBaseUrl = getStrapiURL();

export const wallOfLoveAPIUrl = (page = 0, pageSize = 6) =>
  `${apiBaseUrl}/api/wall-of-loves?populate=profileImage&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=rank:ASC,createdAt:DESC`;

const WallOfLove = ({ data: { data, meta } = {}, showTitle, courseType }) => {
  const pagination = meta?.pagination;
  const sortedData = customCourseTypeComparator(data || [], courseType);

  const [posts, setPosts] = useState(sortedData);
  const [pageCount, setPageCount] = useState(pagination?.page || 0);

  const getMorepost = async () => {
    try {
      const { data } = await axios.get(wallOfLoveAPIUrl(pageCount + 1));
      const sortedPost = customCourseTypeComparator([...posts, ...data?.data], courseType);
      setPosts(sortedPost);
      setPageCount(pageCount + 1);
    } catch (error) {
      console.error(error);
    }
  };

  if (!data || !data.length) return null;

  return (
    <div>
      {showTitle && (
        <section>
          <h5 className="header-h2 flex items-center p-6">
            Wall of Love
            <img src="/assets/icons/heart.png" alt="heart" className="ml-4 h-7" />
          </h5>
        </section>
      )}
      <Masonry
        breakpointCols={{
          default: 3,
          1100: 3,
          768: 1,
          500: 1,
        }}
        className="flex"
      >
        {posts?.map(
          (
            {
              attributes: {
                fullName,
                designation,
                companyName,
                profileImage,
                postLink,
                postedAt,
                postContent,
              },
            },
            index
          ) => (
            <div
              key={index}
              className="m-3 inline-block break-inside-avoid rounded-lg [page-break-inside:'avoid']"
            >
              <section
                className={`relative flex w-full items-start gap-x-2 rounded-2xl border border-gray-light py-5 px-3 delay-200 duration-300 ease-linear hover:scale-105`}
              >
                {profileImage?.data && (
                  <img
                    src={getImgUrl(profileImage)}
                    alt="profile"
                    loading="lazy"
                    className="h-10 w-10 rounded-full object-cover"
                  />
                )}
                <div className="z-0 flex flex-col items-start gap-y-2 px-1 py-1 text-left text-xs font-extrabold">
                  <h3 className="font-satoshi-medium text-base">{fullName}</h3>
                  <p className="font-satoshi-medium text-xs text-gray-300">
                    {designation}
                    {companyName && <span>&nbsp;At {companyName}</span>}
                  </p>
                  <p className="font-satoshi-normal ml-1 text-xs">{postContent}</p>
                  {postedAt && (
                    <p className="ml-1 font-satoshi-medium text-xs text-gray-300">
                      {moment(postedAt).format('hh:mm A, Do MMM')}
                    </p>
                  )}
                </div>
                {postLink && (
                  <a href={postLink} target="_blank" rel="noreferrer">
                    <img
                      className="absolute top-4 right-4 z-10"
                      src="/assets/icons/linkedin-mini.png"
                      height={20}
                      width={20}
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                )}
              </section>
            </div>
          )
        )}
      </Masonry>
      {posts?.length < pagination?.total && (
        <div className="mt-12 flex items-center justify-center font-satoshi-bold font-bold ">
          <button
            onClick={getMorepost}
            className="rounded-md border-2 bg-white px-16 py-2 uppercase text-red"
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default WallOfLove;
