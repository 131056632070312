import $ from 'jquery';

export function sectionScroll({ e, ref, topValue = 120 }) {
  e.preventDefault();
  $('html, body').animate(
    {
      scrollTop: $(ref).offset().top - topValue,
    },
    500
  );
}
