import { getImgUrl } from '@utils/functions/getImgUrl';
import Image from 'next/image';

const SuccessCard = ({ story }) => {
  const {
    attributes: {
      avatar,
      hike,
      previousDesignation,
      companyLogo,
      tagline,
      studentName,
      studentDesignation,
      placedAt,
      cohortYear,
    } = {},
  } = story;

  return (
    <section className="shadow--blue mx-auto h-fit w-[21rem]  pb-6 text-left child:mt-2 child:px-6">
      <p className="h-36 overflow-hidden rounded-xl bg-gray-500 p-4 text-start text-[0.9rem] italic leading-5 text-white lg:p-6 lg:py-6 lg:text-sm">
        {tagline}
      </p>
      <section className="flex justify-between pt-4">
        <h3 className="inline-block w-fit whitespace-nowrap text-base">{studentName}</h3>
        <div className="-mt-14 h-[5.5rem] w-[5.5rem] overflow-hidden rounded-full">
          <Image
            height={120}
            width={120}
            alt="image"
            className="bg-gray object-contain"
            src={getImgUrl(avatar)}
          />
        </div>
      </section>
      <p className="h-6 text-sm">{previousDesignation}</p>
      <Image
        height={66}
        width={66}
        alt="arrow-down"
        objectFit="contain"
        src="/assets/courses/stories/arrowDown.svg"
      />
      <p className="text-sm">{studentDesignation}</p>
      <section className="flex items-end justify-between">
        <Image
          alt="company"
          src={getImgUrl(companyLogo)}
          objectFit="contain"
          height={45}
          width={120}
        />
        {hike ? (
          <div className="flex items-center gap-x-1 font-satoshi-medium  font-satoshi-medium text-sm font-semibold text-egg-blue-700">
            <Image
              src="/assets/courses/stories/trendingarrow.svg"
              alt="trending-arrow"
              height={10}
              width={20}
            />
            {hike}% hike
          </div>
        ) : null}
      </section>
    </section>
  );
};

export default SuccessCard;
