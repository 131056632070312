import { IsMobile } from '@lib/isMobile';
import { useRouter } from 'next/router';
import React from 'react';

const RecognizedBy = ({ showBanner = false, dark = false }) => {
  const router = useRouter();
  return (
    <section className="overflow-hidden rounded">
      {showBanner ? (
        <>
          <img
            src={`/assets/award-banner-mob.png`}
            className="w-full object-contain md:hidden"
            alt="award-banner"
            loading="lazy"
          />
          <img
            src={`/assets/award-banner.png`}
            className="hidden w-full object-contain md:block"
            alt="award-banner"
            loading="lazy"
          />
        </>
      ) : (
        <section className={`${router.route !== '/' && 'bg-blue-light-1'} py-6`}>
          <h3
            className={`mb-3 font-satoshi-regular font-normal tracking-normal ${
              dark ? 'text-white' : 'text-[#737C8A]'
            }`}
          >
            Recognized as emerging Leaders in Tech Education{' '}
          </h3>
          <div className="flex items-center justify-center gap-8">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <img
                  src={`/assets/award-${index + 1}.png`}
                  key={index}
                  className="h-14 object-contain lg:h-[5.5rem]"
                  alt="award-img"
                />
              ))}
          </div>
        </section>
      )}
    </section>
  );
};

export default RecognizedBy;
