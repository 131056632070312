import React, { useEffect, useState, createContext, useContext } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import SiteHeader from '@components/shared/SiteHeader';
import { getUtmQueryString } from '@lib/getUtmQueryString';
import {
  Banner,
  HiringCompanies,
  Success,
  WallOfLove,
  NavigationMenu,
  PaymentPlans,
  LearningEssentials,
  FAQSection,
} from '@components/local';
import { getStrapiURL } from '@lib/api';
import FlexibleFeatures from '@components/local/courses/flexible-features';
import { wallOfLoveAPIUrl } from '@components/local/wall-of-love/WallOfLove';
import { COURSE_TYPE, GOAL_TYPE, PLAN_TYPE } from '@lib/constants';
import CertificationSection from '@components/local/courses/learning-essentials/CertificationSection';
import { useDispatch } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { faqSchemaGenerator } from 'config/seo';
import EventBanner from '@shared/bytes/EventBanner';
import RecognizedBy from '@components/reuseable/RecognizedBy';
import { customCourseTypeComparator } from '@utils/functions/throttle';
import { checkCityBasedSlug, checkifUGCourse, replaceTemplateStrings } from '@utils/helper';
import { getImgUrl } from '@utils/functions/getImgUrl';
import BackToTop from '@components/reuseable/BackToTop';
import AwardsAndRecognition from '@components/shared/AwardsAndRecognition';
import Disclaimer from '@components/shared/Disclaimer';
import { Primary, SecondaryColor } from 'stories/Button.stories';
import fetchProgramCohortData from '@utils/functions/fetchProgramCohort';
import ProgramCohort from '@components/local/courses/ProgramCohort';
import Placements from '@components/local/courses/banner/Placements';
import AcademicBoard from '@components/local/courses/AcademicBoard';
import EducatorsAndMentors from '@components/local/courses/Educators/EducatorsAndMentors';
import WhyAlmaBetter from '@components/local/courses/program-features/WhyAlmaBetter';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import Curriculum from '@components/local/courses/curriculum';
import ProjectAndAssesment from '@components/local/courses/practice/ProjectAndAssesment';
import GenAIProjects from '@components/local/courses/practice/GenAIProjects';

const apiBaseUrl = getStrapiURL();
export const CourseDataContext = createContext();
const CourseDetailsPage = ({
  courseData,
  faqs,
  hiringPartnersList,
  videoTestimonials,
  wallOfLove,
  pressHighlights,
  batchDetails,
  coachesData,
}) => {
  const [topValue, setTopValue] = useState('');

  useEffect(() => {
    if (document.getElementsByClassName('promotion-visible')[0]) {
      setTopValue('promotion-visible');
    }
    if (document.getElementsByClassName('promotion-hidden')[0]) {
      setTopValue('promotion-hidden');
    }
  }, [topValue]);

  const router = useRouter();
  const { query } = router;
  const { isCitySlug, slug } = checkCityBasedSlug(query.courseSlug);
  const courseSlug = isCitySlug ? slug : query.courseSlug;
  const queryString = getUtmQueryString(query);
  const exploreCTA = courseData?.freeTrialLink;
  const dispatch = useDispatch();
  const {
    buttonText,
    buttonLink,
    freeTrialLink,
    freeTrialText,
    exploreCurriculumLink,
    certificate,
    faqCategory,
    courseInterest: course_interest,
  } = courseData;

  const isUGDiploma =
    courseData?.planType === PLAN_TYPE.UG_COURSE || checkifUGCourse(query.degreeSlug);
  const isPGCourse = courseData?.planType === PLAN_TYPE.PG_CERTIFICATION;
  const courseCTAs = {
    buttonText,
    buttonLink,
    freeTrialLink,
    freeTrialText,
    exploreCurriculumLink,
  };

  const sortedHiringPartnersList = customCourseTypeComparator(hiringPartnersList);

  const openBrouchureWidget = () => {
    dispatch({ type: 'SET_BROCHURE_LINK', payload: getImgUrl(courseData?.Brochure) });
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.brouchure });
  };

  const openPlacementWidget = () => {
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: sourceType.placement_report });
  };

  const DownloadBrouchure = ({ outline, disabled = false, size = 'large', capitalCase = true }) => {
    const context = {
      onClick: openBrouchureWidget,
      label: 'Download Brochure',
      size,
      capitalCase,
      disabled,
    };
    return <>{outline ? <SecondaryColor {...context} /> : <Primary {...context} />}</>;
  };

  const DownloadPlacementReport = ({
    outline,
    disabled = false,
    size = 'large',
    capitalCase = true,
  }) => {
    const context = {
      onClick: openPlacementWidget,
      label: 'Download Placement Report',
      size,
      capitalCase,
      disabled,
    };
    return <>{outline ? <SecondaryColor {...context} /> : <Primary {...context} />}</>;
  };

  useEffect(() => {
    dispatch({ type: 'SET_COURSE_INTEREST', payload: course_interest });

    return () => {
      dispatch({ type: 'SET_COURSE_INTEREST', payload: '' });

      if (sessionStorage.getItem('by_form_input')) {
        sessionStorage.removeItem('popup_viewed');
        sessionStorage.removeItem('by_form_input');
        dispatch({
          type: 'SET_WIDGET_SOURCE',
          payload: '',
        });
      }
    };
  }, [courseData?.courseInterest]);

  const paymentPlans = {
    tuition_fee: courseData?.coursePlans,
    career_services_fee: courseData?.careerServicesFeeCertification,
  };

  const isWeb3Course = courseData?.courseType === COURSE_TYPE.WEB3;

  const { MetaTitle, MetaDescription, MetaUrl, image, structuredData } = courseData?.seo || {};

  const webCoaches = {
    head: batchDetails && {
      ...batchDetails?.web_coach?.data,
      batchName: batchDetails?.batchName,
    },
    coaches: coachesData,
  };

  const [cohortData, setCohortData] = useState({});

  useEffect(() => {
    const { courseType, planType } = courseData;

    fetchProgramCohortData(courseType, planType).then((res) => {
      setCohortData(res);
    });

    return () => setCohortData({});
  }, [query]);

  const placementDesc = `Kick start your {{courseName}}
  career today with our flagship program in collaboration with E&ICT IIT Guwahati. Learn
  {{courseName}} with top engineers. Challenge yourself by building real time
  projects.`;

  const assessmentDesc = `Dive into real-world projects and sharpen your interview skills with mock interviews,
  preparing you for success in the competitive {{courseNameSmall}} landscape.`;

  const capstoneProjects = courseData?.capstoneProject.filter((project) => !project.isGenAiProject);
  const genAiProjects = courseData?.capstoneProject.filter((project) => !!project.isGenAiProject);

  return (
    <CourseDataContext.Provider value={{ ...courseData }}>
      <SiteHeader
        title={MetaTitle}
        desc={MetaDescription}
        url={MetaUrl}
        image={image?.data?.attributes?.url}
        structuredData={structuredData}
        customSchema={[
          !isCitySlug && faqSchemaGenerator(faqs?.data?.attributes?.faqCategory?.[0]?.faq),
        ]}
      />
      <BackToTop />

      <main className={`pb-9 [&>section]:mx-4  [&>section]:lg:mx-20`} id="modal">
        {' '}
        <Banner
          courseData={courseData}
          queryString={queryString}
          courseSlug={courseSlug}
          nextCohort={cohortData}
          degree={isUGDiploma}
          UGCourse={isUGDiploma}
          pgCourse
          isWeb3Course={isWeb3Course}
        />
        <div className="pb-[40px]">
          <Placements
            DownloadPlacementReport={DownloadPlacementReport}
            description={replaceTemplateStrings(placementDesc, {
              courseName: isWeb3Course ? 'Web Development' : 'Data Science',
            })}
          />
        </div>
        <NavigationMenu exploreCTA={exploreCTA} degree={isUGDiploma} />
        {/*  */}
        <AcademicBoard
          program={courseData?.IITCollaborationValueProps}
          subTitle={'E&ICT, IIT Guwahati'}
          title={'In Collaboration with '}
          isBootcampCourse
        />
        {videoTestimonials?.length > 0 && (
          <Success
            stories={videoTestimonials}
            DownloadBrouchure={DownloadBrouchure}
            courseCTAs={courseCTAs}
            degree
            heading={courseData?.careerImpactHeading}
            description={courseData?.careerImpactDescription}
            isWeb3Course={isWeb3Course}
          />
        )}
        <Curriculum
          sectionDescription={courseData?.curriculumSectionDescription}
          learningEssentials={courseData?.learningJourneyElective}
          isWebCourse={isWeb3Course}
        />
        {/* Unlock your Data Science Certification */}
        <CertificationSection
          certificateData={certificate}
          nextCohort={cohortData}
          courseCTAs={courseCTAs}
          DownloadBrouchure={DownloadBrouchure}
          UGCourse={isUGDiploma}
          degree
          courseData={courseData}
          newTitle={isWeb3Course ? 'Unlock your Web Development' : 'Unlock your Data Science'}
          subTitle={'Certification'}
        />
        <LearningEssentials
          courseData={courseData}
          courseCTAs={courseCTAs}
          AlmaX
          UGCourse={isUGDiploma}
          degree={isUGDiploma}
          className="bg-black pb-12 child:text-white"
        />
        <EducatorsAndMentors educatorsList={webCoaches.coaches} />
        <WhyAlmaBetter />
        <FlexibleFeatures
          features={courseData?.flexibleFeatures}
          DownloadBrouchure={DownloadBrouchure}
          DownloadPlacementReport={!isUGDiploma && DownloadPlacementReport}
        />
        <ProjectAndAssesment
          pgCourse
          projects={capstoneProjects}
          description={replaceTemplateStrings(assessmentDesc, {
            courseNameSmall: isWeb3Course ? 'web development' : 'data science',
          })}
        />
        <GenAIProjects projects={genAiProjects} />
        <HiringCompanies
          hiringPartners={sortedHiringPartnersList}
          DownloadPlacementReport={DownloadPlacementReport}
          newHiring
        />
        <PaymentPlans
          courseCTAs={courseCTAs}
          coursePlans={paymentPlans}
          courseData={courseData}
          nextCohort={cohortData}
          DownloadBrouchure={DownloadBrouchure}
          UGCourse={isUGDiploma}
          degree={isUGDiploma}
        />
        <div className="child:mb-28 child:scroll-mt-144 [&>section]:mx-4  [&>section]:lg:mx-20">
          <ProgramCohort cohortData={cohortData} buttonLink={buttonLink} />
          {courseData?.notes && (
            <Disclaimer notes={courseData?.notes || ''} additionStyle={'w-[87%]'} />
          )}
          <FAQSection faqs={faqCategory} heading={courseData?.faqHeading} />
          <EventBanner />
          <AwardsAndRecognition pressHighlights={pressHighlights} showRecognization />
          <section>
            {/* <RecognizedBy /> */}
            <RecognizedBy showBanner />
          </section>
          <section className="flex flex-col gap-y-[60px] py-[40px] md:py-[80px]">
            <NewSectionTitle
              text={'What our learners say about'}
              highlightedText={'AlmaBetter Programs'}
              underlineStyle={'underline-offset-[12px]'}
            />
            <WallOfLove data={wallOfLove} courseType={courseData?.courseType} />
          </section>
        </div>
      </main>
    </CourseDataContext.Provider>
  );
};

export default CourseDetailsPage;

export async function getStaticProps({ params }) {
  const { courseSlug } = params;

  try {
    const { isCitySlug, slug } = checkCityBasedSlug(courseSlug);

    const accessSlug = isCitySlug ? slug : courseSlug;

    const URL = `${apiBaseUrl}/api/${isCitySlug ? 'web-course-city-wises' : 'courses'}`;

    const { data: webCourseData } = await axios.get(
      `${URL}?filters[planType][$ne]=${PLAN_TYPE.MASTERS_DEGREE}&populate=howItWorks.steps,courseFeatures,AlmaX,AlmaX.eligibility,AlmaX.eligibility.icon,AlmaX.learningJourneyElective.learningJourney.modules,Certificates.Image,Certificates.Features,Certificates.Features.Icon,partnerUniversities.Icon,
        AlmaX.learningJourneyElective.learningJourney.modules.tools,AlmaX.learningJourney.modules.learningTopics,AlmaX.learningJourney.electives.topics,` +
        `AlmaX.learningJourney.Elective.learningJourney.electives.outcomes,AlmaX.almaxCarrierServices,AlmaX.almaxCarrierServices.icon,` +
        `learningJourneyElective.learningJourney.modules.tools,flexibleFeatures.metrics.icon,learningJourneyElective.learningJourney.modules.learningTopics,` +
        `learningJourneyElective.learningJourney.electives.topics,learningJourneyElective.learningJourney.electives.outcomes,careerTrack.topic,` +
        `courseCurriculumModule.topic,courseCurriculumModule.tools,elective.topics,elective.outcomes,capstoneProject.tools,capstoneProject.projectImage,capstoneProject.projectDescription,capstoneProject.isGenAiProject` +
        `successMetrics.metrics.icon,coursePlans.plan.PlanIcon,coursePlans.planFeatures,coursePlans.paymentFeatures,coursePlans.feesPolicy,coursePlans.FeesPolicyIcon,coursePlans.Features,` +
        `coursePlans.Partners.Icon,courseDetails.DetailsMetric.icon,Curriculum.ModuleTitle,Instructors.avatar,Instructors.comapnyImage,Mentors.avatar,Mentors.comapnyImage,programFeatures.features.icon,` +
        `placementDrive.features.icon,courseFaq,certificate.images,community.image,courseIcon,courseImage,onGoingBatches,coaches.DisplayPicture,coaches.CompanyImage,gamifiedLearning,partnerIcon,` +
        `whyRefundFees.features.icon,seo.image,faqCategory.faq,Brochure,careerServicesFee,IITCollaborationValueProps,IITCollaborationValueProps.features,IITCollaborationValueProps.features.icon,` +
        `IITCollaborationValueProps.Image,careerServicesFee.features,careerServicesFeeCertification,careerServicesFeeCertification.Features,careerServicesFeeCertification.` +
        `Partners,careerServicesFeeCertification.Partners.Icon&filters[slug][$eq]=${courseSlug}`
    );

    if (!webCourseData?.data || !webCourseData.data.length) {
      return {
        notFound: true,
      };
    }

    const courseData = webCourseData.data[0].attributes;
    const res = await axios.get(`${apiBaseUrl}/api/faq?populate=faqCategory.faq`);
    const { data: hiringPartners } = await axios.get(
      `${apiBaseUrl}/api/hiring-partner?populate=partners.images`
    );

    const { data: videoTestimonials } = await axios.get(
      `${apiBaseUrl}/api/testimonials?populate=course&filters[goalType][$eq]=${
        courseData?.courseType === 'data-science' ? 'dataScience' : 'web3'
      }&filters[rank][$notNull]=true&populate=cardImage&populate=avatar&populate=companyLogo&pagination[page]=1&pagination[pageSize]=16&sort[0]=rank`
    );

    const { data: wallOfLove } = await axios.get(wallOfLoveAPIUrl());

    const { data: pressHighlights } = await axios.get(
      `${apiBaseUrl}/api/press-hightlight?populate[highlights][populate]=*&populate[highlights][filters][web_courses][slug][$eq]=${accessSlug}`
    );

    const {
      data: { data: upcomingBatchByCourse },
    } = await axios.get(
      `${apiBaseUrl}/api/courses?filters[planType][$ne]=masters_degree&fields[0]=slug&populate=upcomingBatch.batchDetails.batchImage,upcomingBatch.batchDetails.web_coach,upcomingBatch.batchDetails.web_coach.DisplayPicture,upcomingBatch.batchDetails.web_coach.CompanyImage,upcomingBatch.batchDetails.web_coach.pastCompanies,upcomingBatch.batchDetails.web_coach.pastCompanies.companyImage&filters[slug][$eq]=${accessSlug}`
    );

    const batchDetails =
      upcomingBatchByCourse?.[0]?.attributes?.upcomingBatch?.batchDetails?.[0] || null;

    const goalType = GOAL_TYPE[accessSlug];

    const {
      data: { data: coachesData },
    } = await axios.get(
      `${apiBaseUrl}/api/career-coaches?populate=CompanyImage,DisplayPicture&pagination[page]=1&pagination[pageSize]=100&filters[id][$ne]=${batchDetails?.web_coach?.data?.id}&filters[GoalType][$eq]=${goalType}`
    );

    return {
      props: {
        courseData,
        faqs: res.data,
        hiringPartnersList: hiringPartners.data.attributes.partners || [],
        videoTestimonials: videoTestimonials.data || [],
        wallOfLove: wallOfLove || [],
        pressHighlights: pressHighlights.data || {},
        batchDetails,
        coachesData,
      },
      revalidate: 3600,
    };
  } catch (error) {
    return { error };
  }
}

export async function getStaticPaths() {
  const { data: courseData = {} } = await axios.get(
    `${apiBaseUrl}/api/courses?filters[planType][$ne]=${PLAN_TYPE.MASTERS_DEGREE}`
  );

  const { data: cityWiseCourseData = {} } = await axios.get(
    `${apiBaseUrl}/api/web-course-city-wises?filters[planType][$ne]=${PLAN_TYPE.MASTERS_DEGREE}`
  );

  const courses = courseData.data.filter((course) => course.attributes.courseType !== 'web3');

  const courseSlug = courses.concat(cityWiseCourseData?.data);

  const paths = courseSlug
    ?.filter(({ attributes }) => attributes.slug)
    ?.map(({ attributes }) => ({
      params: { courseSlug: attributes.slug },
    }));
  return { paths, fallback: 'blocking' };
}
