import React from 'react';
import cn from 'classnames';

function TopicCard({ topics = [], duration, layoutStyle }) {
  return (
    <div
      className={cn(
        'flex h-[546px] flex-col gap-y-[26px] rounded-lg bg-[#1A1A1A] px-[32px] pt-[32px] pb-[36px]',
        layoutStyle
      )}
    >
      <div className="flex items-center justify-between">
        <p className="font-satoshi-bold text-[20px] leading-[30px] text-white">Topic</p>
        <p className="font-satoshi-medium text-[16px] leading-6 text-[#EAECF0]">
          Duration: <span className="text-red">{duration}</span>
        </p>
      </div>

      <div style={{ background: '#2B2B2B', width: '100%', height: '2px' }} />

      <ul className="custom-scroll grid list-outside list-disc gap-x-[32px] overflow-y-scroll pl-[32px]">
        {topics.map(({ id, topic }) => (
          <li key={id} className="font-satoshi-medium text-[18px] leading-[44px] text-[#EAECF0]">
            <h5 className="font-satoshi-medium font-medium ">{topic}</h5>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TopicCard;
