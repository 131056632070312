import Tooltip from '@components/shared/tooltip/Tooltip';
import Image from 'next/image';

const accreditedBy = [
  {
    iconSrc: '/assets/degrees/bologna-process.png',
    alt: 'bologna process',
    content: (
      <div className="w-[9rem] text-xs font-normal text-gray-300 lg:w-[18rem]">
        The <strong>Bologna Process</strong> names the agreements which harmonize{' '}
        <strong>accreditation standards of Europe.</strong> It created the{' '}
        <strong>European Credit transfer System</strong>
      </div>
    ),
  },
  {
    iconSrc: '/assets/degrees/european-HEA.png',
    alt: 'european HEA',
    content: (
      <div className="w-[9rem] text-xs font-normal text-gray-300 lg:w-[18rem]">
        The <strong>European Higher Education Area</strong> (EHEA) comprises <strong>49</strong>{' '}
        member countries- more than just Europe. All Higher Education Institutions in EHEA use ECTS
        for <strong>accrediting courses and degrees</strong>
      </div>
    ),
  },
  {
    iconSrc: '/assets/degrees/european-commission.png',
    alt: 'european commission',
    content: (
      <div className="w-[9rem] text-xs font-normal text-gray-300 lg:w-[18rem]">
        The <strong>European Commission</strong> is an executive branch of the{' '}
        <strong>European Union</strong>. It is responsible for the Union's administrative
        operations, including education initiatives and policy-making. It oversees the{' '}
        <strong>ECTS system</strong> and all EHEA countries have adopted the{' '}
        <strong>European Standards and Guidelines</strong>
      </div>
    ),
  },
  {
    iconSrc: '/assets/degrees/MFHEA.png',
    alt: 'MFHEA',
    content: (
      <div className="w-[9rem] text-xs font-normal text-gray-300  lg:w-[18rem]">
        The <strong>Malta Further and Higher Education Authority</strong> (MFHEA) is a regulatory
        authority that <strong>implements European standards and guidelines</strong> with the aim of
        further promoting quality in higher education
      </div>
    ),
  },
];

const AccreditationList = () => {
  return (
    <div className="flex items-center gap-4">
      {accreditedBy.map(({ iconSrc, alt, content }, i) => (
        <Tooltip key={`${i}-${alt}`} content={content}>
          <div className="mx-auto flex h-[100px] w-fit items-center justify-center md:mx-0 md:aspect-square">
            <Image
              src={iconSrc}
              alt={alt}
              objectFit="contain"
              className="min-w-[3rem] max-w-[5rem]"
              width={100}
              height={66}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default AccreditationList;
