import { COURSE_TYPE, COURSE_TYPE_SLUG_MAPPING } from '@lib/constants';
import { useRouter } from 'next/router';

const ElectiveCard = ({ data, electiveNumber, degree }) => {
  const { query } = useRouter();
  const isDegreeCS =
    query?.degreeSlug === COURSE_TYPE_SLUG_MAPPING[COURSE_TYPE.MASTER_COMPUTER_SCIENCE];

  const { title, topics, outcomes } = data;
  return (
    <div
      className={`shadow--blue flex-grow overflow-hidden text-center child:p-3 child:px-6  ${
        degree ? 'rounded-[10px] md:max-w-[24rem]' : 'rounded-3xl md:w-[19rem]'
      }`}
    >
      <div className="bg-gray-500 text-white">
        {degree ? (
          <div className="flex items-center justify-between">
            <div className="font-satoshi-medium text-base font-semibold uppercase">
              Specialisation {electiveNumber}
            </div>
            <div className="font-normal">{isDegreeCS ? 35 : 30} Credits</div>
          </div>
        ) : (
          <div className="font-satoshi-medium text-base font-semibold uppercase">
            Specialisation {electiveNumber}
          </div>
        )}
      </div>{' '}
      <h6 className="text-red">{title}</h6>
      <section className="flex flex-col items-start gap-2">
        {!degree ? <h6 className="font-satoshi-medium font-medium ">topics</h6> : null}
        <p className="flex list-disc flex-col items-start text-left">
          {topics?.map((item) => (
            <li className="text-[16px] leading-7 text-gray-300 " key={item.id}>
              {item.topic}
            </li>
          ))}
        </p>
        {!degree ? (
          <>
            <h6 className="my-2 font-satoshi-medium font-medium ">career outcome</h6>

            <p className="flex flex-wrap items-start gap-3 pb-5">
              {outcomes?.map((outcome) => (
                <p
                  key={outcome.id}
                  className="rounded-xl border border-egg-blue-700 px-2 text-[16px] leading-7 text-egg-blue-700 lg:px-4  lg:text-sm"
                >
                  {outcome.topic}
                </p>
              ))}
            </p>
          </>
        ) : null}
      </section>
    </div>
  );
};
const Elective = ({ elective, degree }) => {
  return (
    <div className="mb-10 md:mb-20">
      <div
        className={`mr-1  flex flex-wrap justify-center  gap-8 gap-x-1 text-center md:mr-0
        xl:justify-around`}
      >
        {elective?.map((data, index, arr) => (
          <>
            <ElectiveCard
              key={data.id}
              data={data?.attributes}
              electiveNumber={index + 1}
              degree={degree}
            />
            {index < arr.length - 1 && index + (1 % 2) != 0 && (
              <div className="flex w-full flex-row items-center justify-center lg:w-11 lg:flex-col">
                <div className="h-0.5 w-full bg-gray/20 lg:h-full lg:w-0.5" />
                <p className="2xl:text-xl my-auto px-4 py-4 text-base">OR</p>
                <div className="h-0.5 w-full bg-gray/20 lg:h-full lg:w-0.5" />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Elective;
