import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Gamified = ({ urls }) => {
  return (
    <div className="flex h-128 items-center justify-center md:h-[30rem]">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={40}
        pagination={{ clickable: true }}
        breakpoints={{
          480: {
            slidesPerView: 1.8,
          },
          768: {
            slidesPerView: 1.2,
          },
          1024: {
            slidesPerView: 1.1,
            spaceBetween: 30,
          },
        }}
        className="!h-full !pb-16 md:!pb-10"
      >
        {urls.map((item) => (
          <SwiperSlide key={item} className="!h-full p-4 md:p-0">
            <iframe
              src={item}
              title="Gamified"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="h-full w-full rounded-xl"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Gamified;
