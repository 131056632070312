import classNames from 'classnames';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import DurationIcon from './DurationIcon';
import { RiArrowRightSLine } from 'react-icons/ri';
import { forwardRef, useContext } from 'react';
import { CourseDataContext } from 'pages/courses/[courseSlug]';

// eslint-disable-next-line react/display-name
export const PracticeTabItem = forwardRef(({ title, desc, onClick, active }, ref) => (
  <div
    ref={ref}
    className={classNames(
      'no-select mr-3 flex-shrink-0 flex-grow-0 cursor-pointer rounded-[10px] border-l-8  p-4 md:mr-0 md:h-fit md:w-full',
      {
        'border-l-transparent bg-white shadow-ab': !active,
        'border-l-red bg-red/5 shadow-ab-red': active,
      }
    )}
    onClick={onClick}
  >
    <div className="my-auto grid grid-cols-1fr-auto gap-x-3">
      <div className="child:hover:text-red">
        <h3
          className={`break-words font-satoshi-medium text-xs text-[16px] font-semibold uppercase leading-6 tracking-[0.12rem] ${
            active ? 'text-red' : 'text-gray-200'
          }`}
        >
          {title}
        </h3>
        <p className="hidden text-[16px] leading-6 text-gray-300 md:block">{desc}</p>
      </div>
      {active && (
        <div className="col-auto my-auto hidden md:block">
          <RiArrowRightSLine className="text-red" />
        </div>
      )}
    </div>
  </div>
));

export const TabItem = ({ active, onClick, title, desc, duration, descClassName, AlmaX }) => {
  const { courseInterest } = useContext(CourseDataContext);
  return (
    <div
      className={classNames(
        `no-select grid cursor-pointer grid-cols-1fr-auto gap-2 rounded-[10px] border-l-8 p-4 shadow-ab child-hover:child-div:text-red md:min-w-[260px] ${
          active && `border-l-red bg-white`
        } `,
        {
          'border-l-transparent bg-white  shadow-ab': !active,
        }
      )}
      onClick={() => {
        onClick();
      }}
    >
      <div className="child:text-black child:hover:text-red">
        <h4
          className={`font-satoshi-medium text-[16px] font-semibold uppercase leading-6 tracking-[0.12em] ${
            active ? 'text-red' : '!text-gray-200'
          }`}
        >
          {title}
        </h4>
        <p className={`${descClassName} text-[16px] leading-6 text-inherit`}>{desc}</p>
      </div>
      {duration && (
        <div>
          {duration && (
            <div className="flex items-center justify-end">
              <DurationIcon fill="#00ae8c" />
              <p className="ml-1 text-xs leading-6 text-egg-blue-700 md:text-sm">{duration}</p>
            </div>
          )}
          <div className="w-full md:hidden">
            {active ? (
              <AiFillCaretUp className="ml-auto" />
            ) : (
              <AiFillCaretDown className="ml-auto" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const Tabs = ({ children }) => (
  <ul className="b-3 h-full list-none p-1 [&>*:not(:first-child)]:mt-5">{children}</ul>
);

export const MainContent = ({ children }) => {
  return <div className="mb-5 w-full rounded-[10px]">{children}</div>;
};

export const TabsContainer = ({ children }) => (
  <div className="mb-3 grid grid-cols-1 md:mb-8 md:grid-cols-2 md:gap-4">{children}</div>
);
