import Image from 'next/image';
import React from 'react';
import SliderComponent from '@components/shared/CustomSlider';
import cn from 'classnames';

// members
import John from '@assets/academic-board/john-jose.png';
// import Ashutosh from '@assets/academic-board/ashutosh-modi.png';
// import Joshua from '@assets/academic-board/joshua-broggi.png';
// import Teri from '@assets/academic-board/teri-cannon.png';
// import Stephen from '@assets/academic-board/stephen-kosslyn.png';
// import Shuo from '@assets/academic-board/shuo-chen.png';
// import Lluis from '@assets/academic-board/lluis-vicent.png';
// import Somyanarayanan from '@assets/academic-board/sadagopan.png';
// import Pramarth from '@assets/academic-board/raj-sinha.png';
// import Ralph from '@assets/academic-board/ralhp-wolff.png';
import Aryabartta from '@assets/academic-board/aryabartta-sahu.webp';
import Gaurav from '@assets/academic-board/gaurav-trivedi.webp';
import Hanumant from '@assets/academic-board/hanumant-singh.webp';

import { getImgUrl } from '@utils/functions/getImgUrl';

import { MdArrowForwardIos, MdArrowBackIosNew } from 'react-icons/md';

import woolfLogo from '@assets/woolf-new.png';
import iitLogo from '@assets/new-iitg-short-logo.png';
import NewSectionTitle from '@components/shared/NewSectionTitle';

const academicUserData = [
  {
    name: 'Dr. Aryabartta Sahu',
    designation: 'Associate Professor',
    graduation: 'Indian Institute of Technology Guwahati',
    profileImage: Aryabartta,
  },
  {
    name: 'Dr. Gaurav Trivedi',
    designation: 'Associate Professor',
    graduation: 'Indian Institute of Technology Guwahati',
    profileImage: Gaurav,
  },
  {
    name: 'Dr. John Jose',
    designation: 'Assistant Professor',
    graduation: 'Indian Institute of Technology Guwahati',
    profileImage: John,
  },
  {
    name: 'Dr Hanumant Singh',
    designation: 'Assistant Professor',
    graduation: 'Indian Institute of Technology Guwahati',
    profileImage: Hanumant,
  },
  // {
  //   name: 'Dr. Joshua Broggi',
  //   designation: 'University of Oxford Founder of Woolf University',
  //   graduation: '',
  //   profileImage: Joshua,
  // },
  // {
  //   name: 'Dr. Teri Cannon',
  //   designation: 'Founding President at Minerva University.',
  //   graduation: '',
  //   profileImage: Teri,
  // },
  // {
  //   name: 'Dr. Stephen M. Kosslyn',
  //   designation: 'Founding President at Minerva University.',
  //   graduation: '',
  //   profileImage: Stephen,
  // },
  // {
  //   name: 'Shuo Chen',
  //   designation: 'Faculty of the University of California, Berkeley.',
  //   graduation: '',
  //   profileImage: Shuo,
  // },
  // {
  //   name: 'Dr. Lluís Vicent',
  //   designation: 'Member of the Royal Academy of European Doctors',
  //   graduation: '',
  //   profileImage: Lluis,
  // },
  // {
  //   name: 'Dr. Sowmyanarayanan Sadagopan',
  //   designation: 'President of IIIT-Bangalore',
  //   graduation: '',
  //   profileImage: Somyanarayanan,
  // },
  // {
  //   name: 'Pramath Raj Sinha',
  //   designation: 'Founder, Harappa Education | Founding Dean, Indian School of Business',
  //   graduation: '',
  //   profileImage: Pramarth,
  // },
  // {
  //   name: 'Ralph Wolff',
  //   designation: 'President of WASC ',
  //   graduation: '',
  //   profileImage: Ralph,
  // },
  // {
  //   name: 'Ashutosh Modi',
  //   designation: 'Assistant Professor',
  //   graduation: 'Indian Institute of Technology, Kanpur',
  //   profileImage: Ashutosh,
  // },
];

function AcademicBoard({ program = null, isBootcampCourse = false, title, subTitle }) {
  if (!program) {
    return <></>;
  }

  const { features } = program;

  return (
    <div
      id={'Academic Board'}
      className="module flex flex-col items-center gap-y-[63px] bg-[#121212] px-[16px] py-[52px] md:px-[124px] md:py-[112px]"
    >
      <NewSectionTitle
        text={title || 'Introducing Master’s Degree in Collaboration with'}
        highlightedText={subTitle || 'Woolf'}
        headingStyle={'text-white'}
      />

      <div className="relative flex flex-col items-center gap-y-[47px] overflow-hidden rounded-[20px] bg-[#1A1A1A] px-[73px] py-[64px] backdrop-blur-[250px]">
        <div className="flex items-center gap-x-[32px]">
          <Image src={'/assets/almabetter.png'} objectFit="contain" width={185} height={34} />
          <span className="text-white">X</span>
          {isBootcampCourse ? (
            <Image src={iitLogo} objectFit="cover" width={190} height={50} />
          ) : (
            <Image src={woolfLogo} objectFit="cover" width={185} height={34} />
          )}
        </div>
        <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-[#A3A3A3] md:text-[18px] md:leading-7">
          {!isBootcampCourse
            ? `Woolf is the world’s first global collegiate higher education institution. It is licensed
          and globally accredited as a higher education institution in the European Union and awards
          ECTS - European Credit Transfer and Accumulation System. Degrees & Certifications awarded
          by Woolf have been evaluated as equivalent to degrees from the US and Canada.`
            : `Our partnership with EICT Academy at IIT Guwahati
            reflects a shared commitment to advancing global education standards.
            This collaboration leverages IIT Guwahati's prestigious reputation in India
            and beyond to offer world-class educational opportunities and foster a global academic community.`}
        </p>
        <div
          className={cn('grid grid-cols-2 gap-x-[48px] gap-y-[40px] md:gap-x-[60px]', {
            'md:grid-cols-4': !isBootcampCourse,
            'md:grid-cols-3': isBootcampCourse,
          })}
        >
          {features.map((feature) => (
            <FeatureCard key={feature.id} {...feature} />
          ))}
        </div>
        <div className="-z-1 absolute -top-20 -left-28 h-[230px] w-[260px] rounded-full bg-[#f0003740] blur-[250px] md:-top-36 md:-left-52 md:h-[440px] md:w-[500px]"></div>
        <div className="-z-1 absolute -bottom-20 -right-28 h-[230px] w-[260px] rounded-full bg-[#f0003740] blur-[250px] md:-bottom-48 md:-right-52 md:h-[440px] md:w-[500px]"></div>
      </div>

      {isBootcampCourse && (
        <>
          <h2 className="flex gap-x-[8px] pb-[13px] text-center font-satoshi-bold text-[30px] leading-[38px] text-white md:text-[36px] md:leading-none">
            Our Academic
            <span className="relative -top-2 text-red underline underline-offset-8">Board</span>
          </h2>

          <div className="w-full">
            <SliderComponent
              swiperOptions={{
                breakpoints: {
                  320: {
                    slidesPerView: 1.5,
                    spaceBetween: 16,
                    slidesPerGroup: 1,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 18,
                    slidesPerGroup: 2,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                    slidesPerGroup: 4,
                  },
                },
              }}
              paginate
              buttonStyle={{
                buttonClass:
                  'col-span-2 md:col-span-5 !bg-[#1A1A1A] !text-white !border-none hover:!bg-[#2B2B2B] rounded-full p-[16px]',
                leftButton: 'place-self-end',
                rightButton: 'place-self-start',
              }}
              paginationLayout="flex items-center gap-x-[12px] col-span-8 md:col-span-2 place-self-center justify-center"
              paginationClass="gray-out-pagination"
              spaceFromCarousel="100px"
              leftBtnElem={<MdArrowBackIosNew size={20} />}
              rightBtnElem={<MdArrowForwardIos size={20} />}
            >
              {academicUserData.map((item) => (
                <MemberCard key={item.name} {...item} />
              ))}
            </SliderComponent>
          </div>
        </>
      )}
    </div>
  );
}

export default AcademicBoard;

function MemberCard({ name, profileImage, designation }) {
  return (
    <div className="relative flex flex-col items-center">
      <Image
        src={profileImage}
        width={302}
        height={287}
        objectFit="cover"
        className="w-full rounded-lg"
        loading="lazy"
      />
      <div className="black-overlay--180deg absolute bottom-0 h-[68px] w-full"></div>

      <div className="absolute -bottom-10 flex h-[90px] w-[260px] cursor-pointer flex-col items-start rounded bg-[#ffffff4d] p-[8px] backdrop-blur-md md:h-[110px] md:p-[16px]">
        <h3 className="text-left font-satoshi-bold text-[16px] leading-6 text-white md:text-[20px] md:leading-[30px]">
          {name}
        </h3>
        <p className="text-left font-satoshi-medium text-[14px] leading-[20px] text-[#E0E0E0] line-clamp-2 hover:line-clamp-none">
          {designation}
        </p>
        <p className="mt-0.5 text-[12px] leading-4 text-[#D0D0D0]">E&ICT, IIT Guwahati</p>
      </div>
    </div>
  );
}

function FeatureCard({ title = 'AlmaBetter’s Graduation Degree', icon }) {
  return (
    <div className="flex flex-col items-center gap-y-[16px]">
      <Image src={getImgUrl(icon)} width={72} height={72} objectFit="contain" loading="lazy" />
      <p className="text-center font-satoshi-medium text-[12px] leading-[18px] text-white md:text-[16px] md:leading-6">
        {title}
      </p>
    </div>
  );
}
