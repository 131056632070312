import React, { useState } from 'react';
import Image from 'next/image';
import { getImgUrl } from '@utils/functions/getImgUrl';

const HiringPartners = ({
  data,
  hiringPartnersList,
  bgColor = 'peach',
  showTabs = false,
  DownloadPlacementReport,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const TYPES = ['FinTech', 'EdTech', 'E-commerce', 'Healthcare', 'Consulting', 'Saas'];

  const [activeType, setActiveType] = useState('FinTech');

  const splitArray = () => {
    const length = hiringPartnersList?.length;
    var i = activeIndex,
      j,
      temp,
      chunk = 16;
    for (i = activeIndex, j = length; i < j; i += chunk) {
      temp = hiringPartnersList?.slice(i, i + chunk);
      return temp.map((item) => (
        <div
          key={item.id}
          className="relative w-[80%] cursor-pointer delay-200 duration-200 ease-linear hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
          style={{ position: 'relative', width: '150px', height: '50px' }}
        >
          <Image
            src={getImgUrl(item?.images)}
            alt={item?.images?.data?.name}
            layout="fill"
            objectFit="contain"
            // loading='lazy'
          />
        </div>
      ));
    }
  };
  const splitArrayMobile = () => {
    const length = hiringPartnersList?.length;
    var i = activeIndex,
      j,
      temp,
      chunk = 12;
    for (i = activeIndex, j = length; i < j; i += chunk) {
      temp = hiringPartnersList?.slice(i, i + chunk);
      return temp.map((item) => (
        <img
          key={item.id}
          src={getImgUrl(item?.images)}
          alt={item?.images?.data?.name}
          className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
          loading="lazy"
        />
      ));
    }
  };
  return (
    <div
      className={`mt-10 flex w-full flex-col items-center justify-between rounded-md pb-8 lg:p-4 bg-${bgColor}`}
    >
      <h2 className="mt-8 mb-4 text-center md:w-10/12">{data?.heading}</h2>
      {data?.description && (
        <div className="text-para mb-4 w-[90%] text-center text-gray-300 lg:w-full">
          {data?.description.split('.')[0]}
          <br />
          {data?.description.split('.')[1]}
        </div>
      )}
      {showTabs && (
        <div className="mt-6 flex justify-center pb-6 text-center">
          {TYPES.map((item, index) => (
            <div
              className={`cursor-pointer px-2 font-satoshi-bold text-xs font-bold  lg:px-4 lg:text-base ${
                activeType === item
                  ? 'border-b-4 border-red'
                  : 'bottom-[#9E9E9E] border-b text-[#9E9E9E] '
              }`}
              key={index}
              onClick={() => setActiveType(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      <div className="flex flex-row items-center justify-center lg:gap-4">
        <button
          onClick={() => activeIndex !== 0 && setActiveIndex(activeIndex - 16)}
          className="hidden lg:block"
        >
          <img
            src="/assets/icons/courses-icons/Group 1000002301.png"
            alt=""
            className="h-10 w-10"
          />
        </button>
        <div
          className={`my-4 hidden max-h-[30rem] grid-cols-3 grid-rows-4 gap-10 bg-white p-4 lg:grid lg:grid-cols-4 lg:gap-14 lg:p-12`}
        >
          {splitArray()}
        </div>
        <div
          className={`my-4 grid max-h-[30rem] grid-cols-3 grid-rows-4 gap-10 bg-white p-4 lg:hidden`}
        >
          {splitArrayMobile()}
        </div>
        <button
          onClick={() =>
            activeIndex < hiringPartnersList.length - 16
              ? setActiveIndex(activeIndex + 16)
              : setActiveIndex(0)
          }
          className="hidden lg:block"
        >
          <img
            src="/assets/icons/courses-icons/Group 1000002369.png"
            alt=""
            className="h-10 w-10"
          />
        </button>
      </div>

      <div className="flex items-center justify-center">
        <button
          onClick={() => activeIndex !== 0 && setActiveIndex(activeIndex - 12)}
          className="lg:hidden"
        >
          <img
            src="/assets/icons/courses-icons/Group 1000002301.png"
            alt=""
            className="mr-2 h-10 w-10"
          />
        </button>
        <button
          onClick={() =>
            activeIndex < hiringPartnersList.length - 12 && setActiveIndex(activeIndex + 12)
          }
          className="lg:hidden"
        >
          <img
            src="/assets/icons/courses-icons/Group 1000002369.png"
            alt=""
            className="disabled h-10 w-10"
          />
        </button>
      </div>
      {DownloadPlacementReport && (
        <div className="mt-6 w-fit md:mt-0">
          <DownloadPlacementReport />
        </div>
      )}
    </div>
  );
};

export default HiringPartners;

export const HiringPartnersList = ({ activeSlide, hiringPartnersList }) => {
  return (
    <>
      {/* web */}
      <div className={activeSlide === 0 ? 'block' : 'hidden'}>
        <div
          className={`my-4 hidden max-h-[30rem] grid-cols-3 gap-10 bg-white p-4 lg:grid lg:grid-cols-4 lg:grid-rows-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(0, 16).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      <div className={activeSlide === 1 ? 'block' : 'hidden'}>
        <div
          className={`my-4 hidden max-h-[30rem] grid-cols-3 gap-10 bg-white p-4 lg:grid lg:grid-cols-4 lg:grid-rows-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(17, 33).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      <div className={activeSlide === 2 ? 'block' : 'hidden'}>
        <div
          className={`my-4 hidden max-h-[30rem] grid-cols-3 gap-10 bg-white p-4 lg:grid lg:grid-cols-4 lg:grid-rows-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(34, 50).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      {/* mobile */}
      <div className={activeSlide === 0 ? 'block' : 'hidden'}>
        <div
          className={`my-4 grid max-h-[30rem] grid-cols-3 gap-10 bg-white p-4 lg:hidden lg:grid-rows-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(0, 12).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      <div className={activeSlide === 1 ? 'block' : 'hidden'}>
        <div
          className={`my-4 grid max-h-[30rem] grid-cols-3 gap-10 bg-white p-4 lg:hidden lg:grid-cols-4 lg:grid-rows-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(13, 25).map((item) => (
            <img
              key={item.id}
              src={item?.images?.data?.attributes?.formats?.thumbnail?.url}
              alt=""
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      <div className={activeSlide === 2 ? 'block' : 'hidden'}>
        <div
          className={`my-4 grid max-h-[30rem] grid-cols-3 grid-rows-4 gap-10 bg-white p-4 lg:hidden lg:grid-cols-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(26, 38).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
      <div className={activeSlide === 3 ? 'block' : 'hidden'}>
        <div
          className={`my-4 grid max-h-[30rem] grid-cols-3 grid-rows-4 gap-10 bg-white p-4 lg:hidden lg:grid-cols-4 lg:gap-14 lg:p-12`}
        >
          {hiringPartnersList?.slice(39, 51).map((item) => (
            <img
              key={item.id}
              src={getImgUrl(item?.images)}
              alt={item?.images?.data?.name}
              className="w-[80%] cursor-pointer hover:scale-150 focus:bg-white focus:shadow-md focus:outline-none focus:ring-0"
            />
          ))}
        </div>
      </div>
    </>
  );
};
